import React, { useEffect, useState } from "react";
import AppTestimonials from "../../../components/AppLandingPageComponents/landingPageComponents/AppTestimonials";
import SparkleBackground from "../../../components/Animations/SparkleBackground";
import AppSectionHeader from "../../../components/AppLandingPageComponents/reUsableComponents/AppSectionHeader";
import AppBannerPage from "../../../components/AppLandingPageComponents/landingPageComponents/AppBannerPage";
import { containerStyles } from "../../../layout/AppLandingPage/AppFooter/appFooterStyles";
import { CiCircleInfo } from "react-icons/ci";
import { GrPowerReset } from "react-icons/gr";

const PlaygroundPage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [generatedOtp, setGeneratedOtp] = useState('');

  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    // Generate a random 6-digit OTP
    const newOtp = Math.floor(100000 + Math.random() * 900000).toString();
    setGeneratedOtp(newOtp);
    setCurrentStep(2);
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    setCurrentStep(3);
    
  };

  const resetPlayground = () => {
    setCurrentStep(1);
    setPhoneNumber('');
    setOtp('');
    setGeneratedOtp('');
  };

   const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
      <div>
          <div className="relative overflow-hidden">
              <div className="relative mx-auto max-w-6xl lg:max-w-7xl px-4 sm:px-6">
                  <div className="pb-24 pt-32">
                      <SparkleBackground>
                          <AppSectionHeader
                              mainTitle="Interactive Playground for UI Testing"
                              description="Try our live playground and experience the seamless UI testing automation that Autoflow brings. Record, customize, and execute tests—all in one place."
                          />
                      </SparkleBackground>


                      <div className="relative max-w-2xl mx-auto rounded-[32px] border border-zinc-500/20 p-12">
                        <button
                              onClick={resetPlayground}
                              disabled={currentStep === 1}
                              title="Reset Playground"
                              className={`absolute top-4 right-4  inline-flex flex-shrink-0 items-center justify-center rounded-xl px-3 py-2 text-sm font-display font-normal
                                ${currentStep === 1 
                                  ? 'bg-zinc-600/20 cursor-not-allowed bg-opacity-50 text-opacity-50 text-secondary-color-light/80' 
                                  : 'border border-zinc-300/10 bg-zinc-600/20   transition text-secondary-color-light/80  hover:border-zinc-500/80 hover:bg-zinc-600/30'
                              }`}
                          >
                              <GrPowerReset />
                          </button>
                          <div className=" shadow-md">
                              {/* Step 1: Phone Number Form */}
                              {currentStep === 1 && (
                                  <form onSubmit={handlePhoneSubmit} className="space-y-4">
                                      <div>
                                          <label htmlFor="phone" className="block text-sm font-medium text-secondary-color-light/90 mb-1">
                                              Enter Phone Number
                                          </label>
                                          <input
                                              id="phone"
                                              type="number"
                                              value={phoneNumber}
                                              onChange={(e) => setPhoneNumber(e.target.value)}
                                              placeholder="Enter your phone number"
                                              required
                                              className={`${containerStyles.input}`}
                                              data-testid="playground-phone-input"
                                          />
                                          <p className="mt-2 text-xs text-gray-400 flex items-center gap-1">
                                              <CiCircleInfo /> This is a testing playground - use any number to test AutoFlow's action recording capabilities.
                                          </p>
                                      </div>
                                      <button
                                          type="submit"
                                          className="w-full px-4 py-2 bg-primary-color text-black rounded-xl"
                                          data-testid="playground-phone-submit"
                                      >
                                          Submit
                                      </button>
                                  </form>
                              )}

                              {/* Step 2: Show OTP and Next Button */}
                              {currentStep === 2 && (
                                  <div className="space-y-4">
                                      <div className="p-4">
                                          <p className="text-sm text-secondary-color-light/90">Your OTP is:</p>
                                          <p className="text-2xl font-bold text-secondary-color-light/90" data-testid="playground-otp">{generatedOtp}</p>
                                          <p className="mt-2 text-xs text-gray-400 flex items-center gap-1">
                                              <CiCircleInfo />  In a real application, this OTP would be sent via SMS. For testing purposes, it's displayed here.
                                          </p>
                                      </div>
                                      <button
                                          onClick={() => setCurrentStep(2.5)}
                                          className="w-full px-4 py-2 bg-primary-color text-black rounded-xl "
                                          data-testid="playground-otp-next"
                                      >
                                          Next
                                      </button>
                                  </div>
                              )}

                              {/* Step 2.5: OTP Verification Form */}
                              {currentStep === 2.5 && (
                                  <form onSubmit={handleOtpSubmit} className="space-y-4" onKeyPress={handleKeyPress}>
                                      <div>
                                          <label htmlFor="otp" className="block text-sm font-medium text-secondary-color-light/90 mb-1">
                                              Enter OTP
                                          </label>
                                          <input
                                              id="otp"
                                              type="text"
                                              value={otp}
                                              onChange={(e) => setOtp(e.target.value)}
                                              placeholder="Enter the OTP"
                                              className={`${containerStyles.input}`}
                                              required
                                              data-testid="playground-otp-input"
                                          />
                                           <p className="mt-2 text-xs text-gray-400 flex items-center gap-1">
                                              <CiCircleInfo /> Use this step to test form validation and error handling in AutoFlow Studio.
                                          </p>
                                      </div>
                                      <button
                                          type="submit"
                                          className="w-full px-4 py-2 bg-primary-color text-black rounded-xl"
                                          data-testid="playground-otp-verify"
                                      >
                                          Verify OTP
                                      </button>
                                  </form>
                              )}

                              {/* Step 3: Success Message */}
                              {currentStep === 3 && (
                                  <div className="text-center">
                                      <h3 className="text-xl font-semibold text-green-600" data-testid="playground-success-message">Verification Complete!</h3>
                                      <p className="text-secondary-color-light/90 mt-2">Thank you for verifying your phone number.</p>
                                  </div>
                              )}
                          </div>
                      </div>

                  </div>
              </div>
          </div>
          <AppTestimonials />
          <AppBannerPage />
      </div>
  );
};

export default PlaygroundPage;