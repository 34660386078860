import React, { useState, useEffect } from "react";
import SparkleBackground from "../../../components/Animations/SparkleBackground";
import AppSectionHeader from "../../../components/AppLandingPageComponents/reUsableComponents/AppSectionHeader";
import { AnimatePresence,motion } from "framer-motion";
import { FaApple,FaLinux,FaGlobe,FaWindows } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";


const DownloadsPage = () => {
  const [downloadOS, setDownloadOS] = useState("Mac");
  const [isLoading, setIsLoading] = useState(true);
  const [showWindowsInstructions, setShowWindowsInstructions] = useState(false);

  useEffect(() => {
    document.title = "Download AutoFlow Studio | Get Started";
  }, [])

  // const handleLoad = () => {
  //   setIsLoading(false);
  // };

  useEffect(()=>{
    if( downloadOS === "Windows" ){
      setShowWindowsInstructions(true);
    }
  }, [ downloadOS ])

  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="relative mx-auto max-w-5xl px-4 sm:px-6">
          <div className="pt-40 sm:pt-56 h-fit">
            {/* <SparkleBackground>
              <AppSectionHeader
                title="Download"
                mainTitle="Get Early Access"
                description="Fill the form below to get early access to Autoflow Studio."
              />
            </SparkleBackground>
            {isLoading && (
              <div className="flex items-center justify-center">
                <span className="loading loading-infinity text-warning w-20 opacity-50"></span>
              </div>
            )}
            <iframe
              src="https://tally.so/embed/nP65D5?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
              // loading="lazy"
              className={`h-[900px] sm:h-[800px] w-full rounded-md ${
                isLoading ? "hidden" : ""
              }`}
              title="Get Early Access to AutoFlow - DownloadPage"
              onLoad={handleLoad}
            ></iframe> */}

            <div className="mx-auto max-w-4xl">
              <div className="hide-scroll mb-10 flex w-full min-w-0 gap-4 overflow-x-auto">
                <a
                  href="https://dl.getautoflowstudio.com/mac"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`my-2 inline-flex flex-shrink-0 grow flex-col items-center justify-center rounded-xl border  px-8 py-4 text-center  transition-opacity hover:bg-zinc-500/10  bg-zinc-500/5  ${
                    downloadOS === "Mac"
                      ? "border-zinc-400/10 opacity-100"
                      : "border-transparent opacity-50"
                  }`}
                  onClick={() => { setDownloadOS("Mac");}}
                >
                  <div className="mb-4 flex flex-shrink-0 rounded-xl border border-zinc-600 bg-zinc-900 p-4">
                    <FaApple className="h-6 w-6 text-secondary-color-light" />
                  </div>
                  <div className="text-sm leading-tight text-secondary-color-light">
                    Mac
                  </div>
                </a>
                <a
                  href="https://dl.getautoflowstudio.com/windows"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`my-2 inline-flex flex-shrink-0 grow flex-col items-center justify-center rounded-xl border  px-8 py-4 text-center  transition-opacity hover:bg-zinc-500/10  bg-zinc-500/5  ${
                    downloadOS === "Windows"
                      ? "border-zinc-400/10 opacity-100"
                      : "border-transparent opacity-50"
                  }`}
                  onClick={() => { setDownloadOS("Windows");}}
                >
                  <div className="mb-4 flex flex-shrink-0 rounded-xl border border-zinc-600 bg-zinc-900 p-4">
                    <FaWindows className="h-6 w-6 text-secondary-color-light" />
                  </div>
                  <div className="text-sm leading-tight text-secondary-color-light">
                    Windows
                  </div>
                </a>
                {/* <button
                  className={`my-2 inline-flex flex-shrink-0 grow flex-col items-center justify-center rounded-xl border  px-8 py-4 text-center  transition-opacity hover:bg-zinc-500/10  bg-zinc-500/5  ${
                    downloadOS === "Linux"
                      ? "border-zinc-400/10 opacity-100"
                      : "border-transparent opacity-50"
                  }`}
                  onClick={() => setDownloadOS("Linux")}
                >
                  <div className="mb-4 flex flex-shrink-0 rounded-xl border border-zinc-600 bg-zinc-900 p-4">
                    <FaLinux className="h-6 w-6 text-secondary-color-light" />
                  </div>
                  <div className="text-sm leading-tight text-secondary-color-light">
                    Linux
                  </div>
                </button> */}
                {/* <button
                  className={`my-2 inline-flex flex-shrink-0 grow flex-col items-center justify-center rounded-xl border  px-8 py-4 text-center  transition-opacity hover:bg-zinc-500/10  bg-zinc-500/5  ${
                    downloadOS === "Web"
                      ? "border-zinc-400/10 opacity-100"
                      : "border-transparent opacity-50"
                  }`}
                  onClick={() => setDownloadOS("Web")}
                >
                  <div className="mb-4 flex flex-shrink-0 rounded-xl border border-zinc-600 bg-zinc-900 p-4">
                    <FaGlobe className="h-6 w-6 text-secondary-color-light" />
                  </div>
                  <div className="text-sm leading-tight text-secondary-color-light">
                    Web App
                  </div>
                </button> */}
                {/* <button
                  className={`my-2 inline-flex flex-shrink-0 grow flex-col items-center justify-center rounded-xl border  px-8 py-4 text-center  transition-opacity hover:bg-zinc-500/10  bg-zinc-500/5  ${
                    downloadOS === "CLI"
                      ? "border-zinc-400/10 opacity-100"
                      : "border-transparent opacity-50"
                  }`}
                  onClick={() => setDownloadOS("CLI")}
                >
                  <div className="mb-4 flex flex-shrink-0 rounded-xl border border-zinc-600 bg-zinc-900 p-4">
                    <FaApple className="h-6 w-6 text-secondary-color-light" />
                  </div>
                  <div className="text-sm leading-tight text-secondary-color-light">
                    CLI
                  </div>
                </button> */}
              </div>
              <div className={`${ showWindowsInstructions ? " opacity-100 " : " pointer-events-none opacity-0 " } transition-opacity duration-500 ease-out text-zinc-200/80 relative flex flex-col mt-12 p-4 rounded-lg bg-zinc-500/5 border border-zinc-400/10 text-sm w-fit mx-auto`}>
                  <button onClick={()=>setShowWindowsInstructions(false)} className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-zinc-400/25 hover:bg-zinc-400/50 rounded-full w-4 h-4 flex items-center justify-center z-20">
                    <RxCross1 className="w-2 h-2"/>
                  </button>
                  <p>
                    If you encounter a warning when opening the installer, please follow these steps:
                  </p>
                  <ol className="mt-1">
                    <li>1. Right click and 'Run as administrator'</li>
                    <li>2. Click on 'More Info'.</li>
                    <li>3. Click the 'Run' button.</li>
                  </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AppBannerPage /> */}
    </div>
  );
};

export default DownloadsPage;
