import React, { useEffect } from "react"; 
import Metors from "../../../components/AppLandingPageComponents/Metors";
import AppHero from "../../../components/AppLandingPageComponents/landingPageComponents/AppHero";
import AppClients from "../../../components/AppLandingPageComponents/landingPageComponents/AppClients";
import AppTestimonials from "../../../components/AppLandingPageComponents/landingPageComponents/AppTestimonials";
import AppBannerPage from "../../../components/AppLandingPageComponents/landingPageComponents/AppBannerPage";
import AppDeveloperTools from "../../../components/AppLandingPageComponents/landingPageComponents/AppDeveloperTools";
import AppStatements from "../../../components/AppLandingPageComponents/landingPageComponents/AppStatements";
import AppSetupTest from "../../../components/AppLandingPageComponents/landingPageComponents/AppSetupTest";
import AppPotential from "../../../components/AppLandingPageComponents/landingPageComponents/AppPotential";
import AppFeatures from "../../../components/AppLandingPageComponents/landingPageComponents/AppFeatures";
import AppOpenSource from "../../../components/AppLandingPageComponents/landingPageComponents/AppOpenSource";
import AppPlatform from "../../../components/AppLandingPageComponents/landingPageComponents/AppPlatform";
import AppCards from "../../../components/AppLandingPageComponents/landingPageComponents/AppCards";
import AppWorks from "../../../components/AppLandingPageComponents/landingPageComponents/AppWorks";
import AppReview from "../../../components/AppLandingPageComponents/landingPageComponents/AppReview";
import NewTestimonial from "../../../components/AppLandingPageComponents/landingPageComponents/AppTestimonials/NewTestimanials";

const HomePage = () => {
  
  useEffect(() => {
    document.title = "Autoflow | Cross Browser Automated Testing Tool for modern QAs | No Coding Required";
  }, []);
  
  return (
    <div className="flex flex-col overflow-clip grow">
      <Metors />
      <AppHero />
      <AppClients />
      <AppCards />
      <AppWorks />
      <AppDeveloperTools />
      <AppStatements />
      {/* <AppSetupTest /> */}
      {/* <AppPotential /> */}
      {/* <AppFeatures /> */}
      <AppOpenSource />
      <AppPlatform />
      <AppReview/>
      <NewTestimonial />
      {/* <AppTestimonials /> */}
      <AppBannerPage />
    </div>
  );
};

export default HomePage;
