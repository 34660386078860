import React, { useState, useEffect } from 'react';
import { getAuthorisationURLWithQueryParamsAndSetState } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { toast } from "react-toastify";
import SessionReact from "supertokens-auth-react/recipe/session";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faGithub } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { logo_square_without_name } from "../../../assets";


function StudioAuthPage() {
    const [doesSessionExist, setDoesSessionExist] = useState(false);

    useEffect(() => {
        async function checkSession() {
            if (await SessionReact.doesSessionExist()) {
                setDoesSessionExist(true);
                window.location.assign(`${process.env.REACT_APP_WEBSITE_DOMAIN}/app/auth/callback/thirdparty`);
            }
        }
        checkSession();
    }, []);

    async function SignInUpGoogleSupertokens() {
        try {
            const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
                thirdPartyId: "google",
                frontendRedirectURI: `${process.env.REACT_APP_WEBSITE_DOMAIN}/app/auth/callback/thirdparty?tid=google`,
            });
            console.log(authUrl);
            console.log(`Redirect URI ${process.env.REACT_APP_WEBSITE_DOMAIN}/app/auth/callback/thirdparty`)
            window.location.assign(authUrl);
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                toast.error(err.message);
            } else if (err.status === 409) {
                toast.error("Email already exists!");
            } else {
                toast.error("Oops! Something went wrong.");
            }
        }
    }

    async function SignInUpGithubSupertokens() {
        try {
            const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
                thirdPartyId: "github",
            frontendRedirectURI: `${process.env.REACT_APP_WEBSITE_DOMAIN}/app/auth/callback/thirdparty/github-app`,
            });

            console.log(`Redirect URI ${process.env.REACT_APP_WEBSITE_DOMAIN}/app/auth/callback/thirdparty/github-app`)
            window.location.assign(authUrl);
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                toast.error(err.message);
            }
            else if (err.status === 409) {
                toast.error("Email already exists!");
            }
            else {
                toast.error("Oops! Something went wrong.");
            }
        }
    }


    if (doesSessionExist) {
        return null; // Optionally, you can return a loading spinner or message here
    }

    return (
        <div
            className="bg-black h-screen w-screen flex flex-col items-center justify-center overflow-x-auto relative"
            style={{
                background:
                    "linear-gradient(90deg, #2B2B02 0%, #0D1010 29%, #000000 48%, #000000 74%, #262B0C 100%)",
            }}
        >
            <div className="flex flex-col items-center justify-center mb-12">
                <div className="flex justify-center flex-grow">
                    <Link to={"/"}>
                        <img
                            src={logo_square_without_name}
                            alt="logo"
                            className="object-cover h-20 w-20"
                        />
                    </Link>
                </div>
                <p className="text-white text-base md:text-xl font-primary font-semibold mt-4">
                    Welcome to{" "}
                    <span className="font-bold text-primary-color">Autoflow</span>
                </p>
            </div>

            <div className="flex flex-col items-center justify-center space-y-6">
                <button
                    onClick={() => SignInUpGoogleSupertokens()}
                    className="bg-primary-color text-white px-6 py-3 rounded-lg flex items-center space-x-3 hover:bg-yellow-600 transition duration-300"
                >
                    <FontAwesomeIcon icon={faGoogle} className="text-black" />
                    <span className="text-black" >Sign in with Google</span>
                </button>
                <button
                    onClick={() => SignInUpGithubSupertokens()}
                    className="bg-gray-800 text-white px-6 py-3 rounded-lg flex items-center space-x-3 hover:bg-gray-900 transition duration-300"
                >
                    <FontAwesomeIcon icon={faGithub} className="text-white" />
                    <span>Sign in with GitHub</span>
                </button>
            </div>
        </div>
    );
}
export default StudioAuthPage;