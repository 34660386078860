import React from 'react';

const BlankSlate = ({
  graphic,
  graphicAlt = "Graphic",
  primaryText,
  secondaryText,
  primaryAction,
  secondaryActionLink,
  border = false,
  graphicClassName = "h-40 sm:h-80 max-h-96 opacity-5 select-none"
}) => {
  return (
    <div className={`h-full flex flex-col items-center justify-center p-6 ${border ? 'border border-border-primary rounded-lg' : ''}`}>
      {graphic && (
        <img
          src={graphic}
          alt={graphicAlt}
          className={graphicClassName}
        />
      )}
      
      {primaryText && (
        <p className="text-white font-display font-bold mt-4 text-center">
          {primaryText}
        </p>
      )}
      
      {secondaryText && (
        <p className="text-text-subtlest text-center mt-2 font-secondary font-light text-xs leading-tight">
          {secondaryText}
        </p>
      )}

      {primaryAction && (
        <div className="mt-4">
          {primaryAction}
        </div>
      )}

      {secondaryActionLink && (
        <div className="mt-2">
          {secondaryActionLink}
        </div>
      )}
    </div>
  );
};

export default BlankSlate;