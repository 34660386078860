import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { FaDiscord } from "react-icons/fa";
import { logo_banner, logo_square_light, logo_square_without_name } from "../../../../assets";

const CommunityModal = () => {
    const [isModalOpen, setModalOpen] = useState(true);
    const [isMinimized, setMinimized] = useState(false);
    const [isMinimizing, setMinimizing] = useState(false);
    const [isText, setIsText] = useState(false);

    const closeModal = () => {
        // Start the minimizing animation
        setMinimizing(true);
        setIsText(true);
        setTimeout(() => {
            document.getElementById('my_modal_2').close();
            setModalOpen(false); // Close the modal
            setMinimizing(false); // Reset animation state
            setMinimized(true); // Show the minimized button
        }, 500); // Animation duration matches the Tailwind animation
        setTimeout(() => {
            setIsText(false); // Reset text state
        }, 3000);
    };

    const handleMouseEnter = () =>{
        setIsText(true);
    }

    const handleMouseLeave = () =>{
        setIsText(false);
    }

    const reopenModal = () => {
        setModalOpen(true);
        setMinimized(false);
        document.getElementById('my_modal_2').showModal();
    };

    useEffect(() => {
        setTimeout(() => {
            setModalOpen(true);
            setMinimized(false);
            document.getElementById('my_modal_2').showModal();
        }, 3000);
    }, [])

    return (
        <>
            {/* Modal */}

            <dialog
                id="my_modal_2"
                className={`modal flex justify-center items-center fixed ${isMinimizing ? "animate-minimize-to-button" : "animate-fade-in" }`}
            >
                <div className="absolute w-full h-full bg-white bg-opacity-5 backdrop-blur-sm"></div>
                <div className="modal-box shadow-xl shadow-black/60 z-20 p-0 relative overflow-visible flex text-text-primary w-fit aspect-[211/139] max-w-2xl rounded-[5px] border border-zinc-900 bg-black"
                    style={{ transformStyle: 'preserve-3d' }}
                >
                    <div className="rounded absolute z-50 h-4 w-full top-0 left-0 bg-gradient-to-b from-white/15 to-transparent">

                    </div>
                    
                    <button
                        tabIndex={2}
                        className="z-40 absolute top-4 right-4 hover:bg-transparent group text-primary-icon-color ml-auto"
                        onClick={closeModal}
                    >
                        <GrClose className="text-base" />
                    </button>

                    <div className="z-30 w-[40%] h-full bg-black rounded-[5px] flex items-center">
                        <img 
                            src="https://ik.imagekit.io/du5yq7zec/Branding/svg-edited.png?updatedAt=1732715015364"
                            className="w-full object-contain" 
                        />
                    </div>
                    <div className="z-30 py-4 px-6 h-full text-center bg-repeat bg-[url(https://ik.imagekit.io/du5yq7zec/Branding/Logo%20for%20export_Pattern.png?updatedAt=1732713123789)] bg-contain bg-black rounded-[5px]">
                        <p className="font-bold text-xl sm:text-2xl mt-10 sm:mt-28">We're building community of Devs & QAs!</p>
                        <p className="mt-2">Join now and get exclusive member perks on AutoFlow Studio App!</p>
                        <a
                            href="https://discord.com/invite/FHXJRkYqEQ"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mt-4 w-full flex justify-center items-center bg-discord-btn text-white px-2 py-2 rounded-md"
                        >
                            <FaDiscord
                                className={`text-xl animate-pulse duration-500 mr-2`}
                            />
                            Join Now
                        </a>
                    </div>

                </div>
            </dialog>

            {/* Minimized Button */}
            {/* {isMinimized && ( */}
                <div
                    className={`fixed bottom-20 cursor-pointer right-5 bg-discord-btn text-white flex items-center gap-4 z-50 transition-all duration-500 rounded-full  overflow-hidden ${isText ? " w-[13.5rem] px-4 py-[0.75rem] " : " p-2 w-10 "}`}
                    onClick={reopenModal}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <img 
                        className="w-8"
                        src="https://cdn.prod.website-files.com/6257adef93867e50d84d30e2/636e0a6cc3c481a15a141738_icon_clyde_white_RGB.png" 
                    />
                    <span className={`transition-all duration-500 flex whitespace-nowrap`}>
                        Join the Community
                    </span>
                </div>
            {/* )} */}

        </>
    );
};

export default CommunityModal;
