import React, { useEffect, useState } from 'react';
import { appTestimonialsData } from '../../../../utils/Data';

const NewTestimonial = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsFading(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) =>
                    prevIndex === appTestimonialsData.length - 1 ? 0 : prevIndex + 1
                );
                setIsFading(false);
            }, 500); // Duration of fade-out and fade-in
        }, 5000); // Change every 5 seconds

        return () => clearInterval(interval);
    }, [appTestimonialsData.length]);

    return (
        <section className="flex justify-center p-2 ">

            
            <div
                className="bg-primary-color-dark shadow-xl p-6 relative rounded-3xl w-full max-w-3xl h-[350px] sm:h-[250px]"
            >
                <span className='absolute -top-5 z-40 -left-20'>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="300"
                    height="300"
                    viewBox="0 0 300 300"
                >
                    <text
                        x="10"
                        y="100"
                        font-family="Arial, sans-serif"
                        font-size="120"
                        fill="hsla(39, 100%, 54%, 1)"
                        font-weight="bold"
                    >
                        ”
                    </text>
                </svg>
            </span>
                {/* Image Slider */}
                <div className="flex-shrink-0 absolute -top-10 transition-transform duration-500 transform ease-in-out border border-zinc-500/20 p-1 rounded-full">
                    <img
                        className={`h-16 w-16 rounded-full transition-all duration-500 ease-out ${isFading ? 'opacity-0' : 'opacity-100'}`}
                        src={appTestimonialsData[currentIndex].img}
                        alt="user img"
                    />
                </div>

                {/* Testimonial Text with Fade-in/out */}
                <div className="ml-6 h-full flex flex-col justify-center">
                    <blockquote
                        className={`text-lg font-medium text-primary-text relative  transition-opacity duration-500 ease-out ${isFading ? 'opacity-0' : 'opacity-100'}`}
                    >
                        <div className="relative flex flex-col ">
                            <div className="block max-w-max bg-gradient-to-r from-white via-white/80 to-white/30 bg-clip-text text-2xl font-semibold text-transparent">
                                {appTestimonialsData[currentIndex].testimonial}
                            </div>
                        </div>
                    </blockquote>
                    <div className="mt-4 flex items-center text-sm text-primary-text">
                        <div className="font-semibold text-primary-color">{appTestimonialsData[currentIndex].name}</div>
                        <span className="mx-1">|</span>
                        <div>{appTestimonialsData[currentIndex].company}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NewTestimonial;
