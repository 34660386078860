import step1 from './google.png';
import step2 from './step3.png';
import step3 from './step3.png';
import step4 from './step4.png';
import step5 from './step5.png';
import step6 from './step6.png';
import step7 from './step7.png';
import step8 from './step8.png';
import output from './output.mp4';

export const tourReportData = {
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    output
}