// import { step1 } from './tourReportData/images';
import { tourReportData as tourReportImages } from "./tourReportData/images"

export const tourReportData = {
    "journeyRunId": "onboarding_tutorial",
    "journeyId": "d5aeeac5-e546-4bc7-9310-b7097258866e",
    "journeyName": "Tutorial Journey",
    "browserType": "CHROMIUM",
    "device": "Desktop Windows",
    "status": "PASSED",
    "journeyDurationMiliseconds": 3999,
    "journeyProgress": 100,
    "reportUrl": "about:blank",
    "networkLogsFileUrl": null,
    "testsData": [
        {
            "testRunId": "aee11ed1-3489-4436-8073-721312ad56c0",
            "flowId": "1fa9f37d-8654-4bf7-8c89-1a88884bf2b6",
            "testName": "Tutorial Test",
            "status": "PASSED",
            "durationMiliseconds": 3999,
            "stepCount": 4,
            "progress": 100,
            "stepsData": [
                {
                    "stepRunId": "803c479e-c20b-48aa-af6f-47a1f41f9945",
                    "stepId": "2c706a00-5347-46da-bd9d-d7e56219c657",
                    "stepName": "Navigate to the website 'google.com'",
                    "stepType": "navigate",
                    "sequenceNumber": 1,
                    "status": "PASSED",
                    "durationMiliseconds": 1650,
                    "isPasswordInput": null,
                    "screenshotUrl":  tourReportImages.step1,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "f29f70ae-8c1d-4d23-b5d1-f560442d6c12",
                    "stepId": "5b0441ee-0325-4e06-91cf-7d9c4348403c",
                    "stepName": "Click on the 'Search' button",
                    "stepType": "click",
                    "sequenceNumber": 2,
                    "status": "PASSED",
                    "durationMiliseconds": 238,
                    "isPasswordInput": null,
                    "screenshotUrl":  tourReportImages.step2,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "3ba7d853-bcdd-44a8-87f9-eabc5209b567",
                    "stepId": "0d6692ce-ee66-446c-a438-d8820e8264ba",
                    "stepName": "Click on the 'Autoflow | Cross Browser' link",
                    "stepType": "click",
                    "sequenceNumber": 3,
                    "status": "PASSED",
                    "durationMiliseconds": 102,
                    "isPasswordInput": null,
                    "screenshotUrl":  tourReportImages.step4,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Click on the 'Playground' link",
                    "stepType": "click",
                    "sequenceNumber": 4,
                    "status": "PASSED",
                    "durationMiliseconds": 2009,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step6,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Click on the 'Enter Phone Number'",
                    "stepType": "click",
                    "sequenceNumber": 5,
                    "status": "PASSED",
                    "durationMiliseconds": 200,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step7,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Click on the phone input field",
                    "stepType": "click",
                    "sequenceNumber": 6,
                    "status": "PASSED",
                    "durationMiliseconds": 2011,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step7,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Fill the phone input field",
                    "stepType": "click",
                    "sequenceNumber": 7,
                    "status": "PASSED",
                    "durationMiliseconds": 20,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step7,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Press Enter on the phone input field",
                    "stepType": "click",
                    "sequenceNumber": 8,
                    "status": "PASSED",
                    "durationMiliseconds": 1009,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step7,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Get the inner text of the element",
                    "stepType": "click",
                    "sequenceNumber": 9,
                    "status": "PASSED",
                    "durationMiliseconds": 2009,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step7,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Click on the 'Next' button",
                    "stepType": "click",
                    "sequenceNumber": 10,
                    "status": "PASSED",
                    "durationMiliseconds": 269,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step7,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Click on the OTP input field",
                    "stepType": "click",
                    "sequenceNumber": 11,
                    "status": "PASSED",
                    "durationMiliseconds": 509,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step7,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Fill the OTP input field with the value",
                    "stepType": "click",
                    "sequenceNumber": 12,
                    "status": "PASSED",
                    "durationMiliseconds": 2129,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step7,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Press Enter on the OTP input field",
                    "stepType": "click",
                    "sequenceNumber": 13,
                    "status": "PASSED",
                    "durationMiliseconds": 231,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step7,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Click on the 'Verify OTP' button",
                    "stepType": "click",
                    "sequenceNumber": 14,
                    "status": "PASSED",
                    "durationMiliseconds": 2009,
                    "isPasswordInput": false,
                    "screenshotUrl":  tourReportImages.step7,
                    "auditReportUrls": null,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                },
                {
                    "stepRunId": "964ccc2c-a496-4183-b89b-98977b176f5e",
                    "stepId": "c5ba9e9a-da31-460d-ad41-aa85d25f57f6",
                    "stepName": "Verify that the text 'Verification Complete!' is visible on the page",
                    "stepType": "click",
                    "sequenceNumber": 15,
                    "status": "PASSED",
                    "durationMiliseconds": 215,
                    "isPasswordInput": false,
                    "auditReportUrls": null,
                    "screenshotUrl":  tourReportImages.step8,
                    "autoflowErrorMessage": null,
                    "errorMessage": null,
                    "errorStack": null,
                    "errorSnippet": null,
                    "errorValue": null
                }
            ]
        }
    ],
    "videoUrl": tourReportImages.output,
    "runType": "CLOUD"
}