import React, { useState, useEffect, useRef } from 'react'

const Tooltip = (props) => {

    const [ coordinates, setCoordinates ] = useState({ top: 0, center: 0 });
    const [ zIndex, setZIndex ] = useState(-9999);
    const [ opacity, setOpacity ] = useState(0);
    const tooltipRef = useRef(null);

    useEffect(() => {
      if(tooltipRef.current) {

        const rect = tooltipRef.current.parentElement.getBoundingClientRect();
        const center = rect.left;
        const top = rect.y;
        setCoordinates({ top, center });

        tooltipRef.current.parentElement.addEventListener("mouseenter", (e) => {
          setZIndex(9999);
          setOpacity(1);
        });

        tooltipRef.current.parentElement.addEventListener("mouseleave", () => {
          setZIndex(-9999);
          setOpacity(0);
        })


        return ()=>{
            tooltipRef?.current?.parentElement?.removeEventListener("mouseenter", () => {
              setOpacity(1);
            });
    
            tooltipRef?.current?.parentElement?.removeEventListener("mouseleave", () => {
              setOpacity(0);
            })
        }
      }
    }, [tooltipRef]);
    
    return (
      <div ref={tooltipRef} onMouseEnter={(e)=>{ e.preventDefault(); e.stopPropagation(); }} 
        className={`${ props.right ? "translate-x-[100%]" 
            : props.top ? "top-0 left-0 -translate-x-[50%] -translate-y-[100%]" 
            : props.left ? "left-0 top-0 bottom-0 -translate-y-[50%] -translate-x-[100%]"
            : props.bottom ? "bottom-0 translate-y-[100%]"
            : "top-0 left-0 -translate-x-[50%] -translate-y-[100%]" } absolute ${ props.className ? props.className : "w-fit" }`} style={{ zIndex: zIndex, opacity: opacity }}
      >
        <div className="relative bg-primary-color-dark rounded-lg border border-[#353535] w-full h-fit p-2 shadow-lg">
            {/* <div className="absolute inset-0 mt-auto translate-y-[100%] border border-t-primary-color-dark border-purple-500 w-3 h-2 mx-auto" style={{ clipPath:"polygon(0 0, 100% 35%, 100% 65%, 0% 100%)" }}></div> */}
            {props.children}
        </div>
      </div>
    )
}

export default Tooltip