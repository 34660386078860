export function rgbaToHsla(rgba) {
    const rgbaPattern = /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/;
    const match = rgba.match(rgbaPattern);
    if (!match) {
        throw new Error("Invalid RGBA format");
    }
    const r = parseInt(match[1]) / 255;
    const g = parseInt(match[2]) / 255;
    const b = parseInt(match[3]) / 255;
    const a = match[4] ? parseFloat(match[4]) : 1;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h = h * 60;
    }
    h = Math.round(h);
    s = Math.round(s * 100);
    l = Math.round(l * 100);
    return `hsla(${h}, ${s}%, ${l}%, ${a})`;
}