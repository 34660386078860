import React, { useState } from 'react';
// import { OutlineArrowBack, OutlineKeyboardArrowUp } from '../../../../shared/assets/icons';
import { useNavigate } from 'react-router-dom';
// import { history } from '../../../helpers/routes';
import Lozenge from './Lozenge';
import HorizontalAccordion from './component/HorizontalAccordion';
import ProgressBar from './component/ProgressBar';
import OverlayModal from './OverlayModal';



const JourneyRunReport = (props) => {
    let reportData = props.report;
    let orgId = props.orgId;
    // const navigate = useNavigate();
    console.log('reportData:', reportData, 'orgId:', orgId);
    const [copySuccess, setCopySuccess] = useState('Copy Sharable URL ');
    const [showVideo, setShowVideo] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handleImageOpen = (step) => {
        setModalData(step);
        document.getElementById('my_modal_1').showModal()
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_WEBSITE_DOMAIN}/report?journey_run_id=${reportData.journeyRunId}&org_id=${orgId}`).then(() => {
            setCopySuccess('Copied to clipboard!');
            setTimeout(() => {
                setCopySuccess('Copy Sharable URL ');
            }, 2000);
        });
    };

    const toggleVideo = () => {
        setShowVideo(!showVideo);
    };

    // const handleBack = () => {
    //     navigate(history);
    // }

    const headCardArr = [
        {
            title: 'Browser',
            subTitle: reportData.browserType
        },
        {
            title: 'Device',
            subTitle: reportData.device
        },
        {
            title: 'Duration',
            subTitle: `${reportData.journeyDurationMiliseconds / 1000} seconds`
        },
        {
            title: 'Tests done',
            subTitle: `${reportData.journeyProgress}%`
        },
    ]

    return (
        <div className="min-h-screen  text-[#b29f8a] font-sans">
            <OverlayModal
                    modalHeadingData={{
                        heading: `Screenshot of step ${modalData?.sequenceNumber}`,
                        onClickFn: () => {
                            document.getElementById('my_modal_1').close()
                            setModalData(null)
                        },
                    }}
                >

                    <div
                        className="max-w-3xl max-h-3xl p-4 rounded-lg shadow-lg"
                        onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking on the image itself
                    >
                        <img
                            src={modalData?.screenshotUrl} // Replace with your image URL
                            alt="Enlarged view"
                            className="w-full h-full rounded-md"
                        />
                    </div>

                </OverlayModal>
            <div className='flex flex-col gap-4 px-20 py-10 '>
                <p className='text-primary-text text-body-lg font-medium'>User Journey Run Report</p>
                <div className='flex items-center justify-between'>
                    <div className="font-semibold text-primary-color text-heading-lg">{reportData.journeyName}</div>
                    <button className="px-5 py-2 text-text-subtle text-body-md font-medium bg-alpha-700 hover:bg-alpha-600 rounded cursor-pointer" onClick={handleCopy}>
                        {copySuccess}
                    </button>
                </div>
            </div>
            <div className="md:px-20 px-10 flex flex-col gap-2">

                <div className='flex gap-5 md:gap-16 md:p-10 p-2 rounded border border-border-primary'>

                    <div className="stat-figure text-secondary w-[55%]">
                        <video style={{height:'256px'}} width="518" controls>
                            <source src={reportData.videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    <div className='flex flex-col w-72 w-[25%] '>
                        {
                            headCardArr?.map((data) => (
                                <div className='pb-4'>
                                    <p className='font-medium text-body-sm text-primary-text text-start '>{data?.title}</p>
                                    <p className='font-semibold text-body-lg text-white'>{data?.subTitle}</p>
                                </div>
                            ))
                        }
                        <Lozenge
                            success={reportData.journeyProgress == 100 ? true : false}
                            remove={reportData.journeyProgress != 100 ? true : false}
                            bold
                        >
                            <span className='p-1'>
                                {reportData.journeyProgress == 100 ? 'TEST SUCCESS' : 'TEST FAILS'}
                            </span>
                        </Lozenge>
                    </div>
                    <div className='flex flex-col gap-6 text-caption font-[400] w-[20%] text-text-subtlest'>
                        <div>
                            <p className='text-start w-24'>Run ID</p>
                            <p>{reportData.journeyRunId}</p>
                        </div>
                        <div >
                            <p className='text-start w-24'>Run Type</p>
                            <p>{reportData.runType}</p>
                        </div>
                    </div>

                </div>

                {reportData.testsData.map((test, index) => (
                    <div className='flex flex-col p-10 rounded border border-border-primary mb-4'>
                        <div className='flex flex-col pb-2.5 w-full'>
                            <p className='font-medium text-code text-primary-text'>Test</p>
                            <p className='font-semibold text-body-lg text-secondary-brand-primary'>{test.testName}</p>
                        </div>
                        <div className='flex gap-16 w-full'>
                            <div className='flex flex-col w-[55%]'>
                                {test.stepsData
                                    .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
                                    .map((step, stepIndex) => (
                                        <div className='mb-1'>
                                        <HorizontalAccordion
                                            isArrow
                                            summaryCustomClass={`border-b border-border-primary p-4 rounded-sm ${step.optionalStep&&step.status=="FAILED"?"bg-surface-accent-orange-primary":step.optionalStep&&step.status == 'PASSED'?"bg-surface-accent-green-primary":step.status=="FAILED"?'bg-surface-accent-red-primary':''}`}
                                            childStyle='p-0'
                                            titleElements={
                                                <div className={`flex items-center grow justify-between ${step.optionalStep&&step.status=="FAILED"?"text-text-accent-orange-primary":step.optionalStep&&step.status == 'PASSED'?"text-text-accent-green-primary":step.status=="FAILED"?'text-text-accent-red-primary':'text-white'} font-medium text-lable`}>
                                                    <div className='flex items-center gap-1 w-full'>
                                                        <span> {step.sequenceNumber}.</span>
                                                        <span className="grow"> {step.stepName}</span>
                                                        {step.optionalStep &&
                                                        <Lozenge
                                                            className=" ml-auto whitespace-nowrap "
                                                            primary
                                                        >
                                                            Optional Step
                                                        </Lozenge>}
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <div className='flex gap-14 bg-alpha-700 mt-2 p-4'>
                                                <div className='w-[40%]'>
                                                   {step.status=="FAILED"||step.status=="SKIPPED DUE TO PARENT FAILURE"?
                                                   <div>
                                                    <p className='text-surface-accent-red-overlay-primary font-medium text-code text-start'>Error Message :</p>
                                                   <span className='font-medium text-code text-white'>{step?.autoflowErrorMessage}</span>
                                                            </div>
                                                            : <img
                                                                title='Click to view'
                                                                onClick={() => handleImageOpen(step)}
                                                                src={step.screenshotUrl}
                                                                alt={`Screenshot of step ${step.sequenceNumber}`}
                                                                className="border border-gray-700 rounded cursor-pointer"
                                                                width="231"
                                                                height="132"
                                                            />}
                                                    </div>
                                                <div className='flex flex-col w-[60%]'>
                                                    <div className='pb-4'>
                                                        <Lozenge
                                                             moved={step.optionalStep&&step.status=="FAILED"? true : false}
                                                             success={step.status == 'PASSED' ? true : false}
                                                             remove={!step.optionalStep&&step.status != 'PASSED' ? true : false}
                                                        >
                                                            <span className='p-1'>
                                                                {step.status} {step.optionalStep?'(OPTIONAL)':''}
                                                            </span>
                                                        </Lozenge>
                                                    </div>
                                                    <div className='pb-4'>
                                                        <p className='font-medium text-code text-primary-text text-start '>Duration</p>
                                                        <p className='font-semibold text-body-lg text-white'>{step.durationMiliseconds / 1000}s</p>
                                                    </div>
                                                    <div className='flex flex-col text-caption font-[400] text-text-subtlest'>
                                                        <p className='text-start w-24'>Step ID</p>
                                                        <p>{step.stepRunId}</p>
                                                    </div>
                                                </div>
                                                <div>

                                                </div>
                                            </div>

                                        </HorizontalAccordion>
                                        </div>
                                    ))}
                            </div>
                            <div className='flex flex-col w-[25%]'>
                                <div className='pb-4'>
                                    <p className='font-medium text-code text-primary-text text-start '>Status</p>
                                    <Lozenge
                                        success={test.status == 'PASSED' ? true : false}
                                        remove={test.status != 'PASSED' ? true : false}
                                        bold
                                    >
                                        <span className='p-1'>
                                            {test.status}
                                        </span>
                                    </Lozenge>
                                </div>
                                <div className='pb-4'>
                                    <p className='font-medium text-code text-primary-text text-start '>Duration</p>
                                    <p className='font-semibold text-body-lg text-white'>{test.durationMiliseconds / 1000}s</p>
                                </div>
                                <div className='pb-4'>
                                    <p className='font-medium text-code text-primary-text text-start '>Steps</p>
                                    <p className='font-semibold text-body-lg text-white'>{test.stepCount}</p>
                                </div>
                                <div className='pb-4'>
                                    <p className='font-medium text-code text-primary-text text-start '>Progress</p>
                                    <p className='font-semibold text-body-lg text-white'>{test.progress}%</p>
                                    <ProgressBar value={test.progress} />
                                </div>
                            </div>
                            <div className='flex flex-col w-[20%] text-caption  font-[400] text-text-subtlest'>
                                <p className='text-start w-24'>Test ID</p>
                                <p>{test.testRunId}</p>
                            </div>
                        </div>


                    </div>))}
            </div>
        </div>
    );
};

export default JourneyRunReport;