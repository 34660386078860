import React, { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { IoDocumentAttachSharp } from "react-icons/io5";
import { MdOutlineFileDownload } from "react-icons/md";
import { appHome, sdkHome } from "../../../routes/routes";
import { menus } from "../../../components/SdkLandingPageComponents/Data";
import { logo, logo_square_without_name } from "../../../assets";

const SideBar = ({logFrom}) => {
  const isMobileQuery = useMediaQuery({ minWidth: 1000 });
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(isMobileQuery);

  useEffect(() => {
    setIsSidebarOpen(isMobileQuery);
  }, [isMobileQuery]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      className={`relative flex flex-col bg-black border-r border-gray-700 h-full shadow-2xl transition-all duration-300 ease-in-out z-[950] ${
        isSidebarOpen ? "w-72" : "w-24"
      }`}
    >
      {/* logo */}
      <div
        className={`flex justify-center h-[5%]  mt-2 ${isSidebarOpen ? "w-60 " : ""}`}
      >
        <Link to={logFrom?appHome:sdkHome}>
          <img
            src={isSidebarOpen ? 'https://ik.imagekit.io/du5yq7zec/Landing%20Page/autoflowLogoWhite.png?updatedAt=1721131360109https://ik.imagekit.io/du5yq7zec/Landing%20Page/autoflowLogoWhite.png?updatedAt=1721131360109https://ik.imagekit.io/du5yq7zec/Landing%20Page/autoflowLogoWhite.png?updatedAt=1721131360109' : logo_square_without_name}
            alt="Autoflow"
            className={`${isSidebarOpen ? "h-12" : "h-10 mt-3"}`}
          />
        </Link>
      </div>
      {/* arrow to close and open */}
      <div
        className={`absolute top-6 z-[999] right-0 translate-x-[50%] transition-all duration-300`}
      >
        <button
          className={`rounded-md bg-gray-900/90 text-white/75 hover:text-white/80 hover:bg-gray-800/80 p-3 ${
            isSidebarOpen && "rotate-180"
          } transition-transform duration-300`}
          onClick={toggleSidebar}
        >
          <FaArrowRightLong className="text-xs" />
        </button>
      </div>

      {/* menus */}
      <div className="flex flex-col justify-between mt-10 h-[95%]">
        <div>
          <ul>
            {menus.map((menu) => {
              const isActive = location.pathname === menu.path;
              return (
                <Link to={logFrom&&menu?.appPath?menu.appPath:menu.path} key={menu.id} className={`${menu?.isHide&&logFrom?'hidden':''}`}>
                  <li
                    className={`flex items-center ${
                      isSidebarOpen
                        ? "flex-row gap-x-4 m-3 mx-5 p-5"
                        : " justify-center p-2 px-1 mx-5 my-4"
                    }   font-primary font-bold border border-black hover:bg-primary-color hover:text-black rounded-xl ${
                      isActive ? "bg-primary-color text-black" : "text-white"
                    }`}
                  >
                    {isSidebarOpen ? (
                      <>
                        {menu.icon}
                        <p>{menu.name}</p>
                      </>
                    ) : (
                      <> {menu.icon}</>
                    )}
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
        <div>
          <ul>
            <Link to="https://autoflow.tools/downloads" target="_blank">
              <li
                className={`flex items-center ${
                  isSidebarOpen
                    ? "flex-row gap-x-4 m-3 mx-5 p-5"
                    : " justify-center p-2 px-1 mx-5 my-4"
                }  text-white font-primary font-bold bg-gray-900/90 border border-gray-700 hover:bg-primary-color hover:text-black rounded-xl  `}
              >
                {isSidebarOpen ? (
                  <>
                    <MdOutlineFileDownload className="text-xl" />
                    <p>Download App</p>
                  </>
                ) : (
                  <>
                    <MdOutlineFileDownload className="text-xl" />
                  </>
                )}
              </li>
            </Link>
          </ul>
          <ul>
            <Link to="https://docs.autoflow.tools" target="_blank">
              <li
                className={`flex items-center ${
                  isSidebarOpen
                    ? "flex-row gap-x-4 m-3 mx-5 p-5"
                    : " justify-center p-2 px-1 mx-5 my-4"
                }  text-white font-primary font-bold bg-gray-900/90 border border-gray-700 hover:bg-primary-color hover:text-black rounded-xl  `}
              >
                {isSidebarOpen ? (
                  <>
                    <IoDocumentAttachSharp className="text-xl" />
                    <p>Documentation</p>
                  </>
                ) : (
                  <>
                    <IoDocumentAttachSharp className="text-xl" />
                  </>
                )}
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
