import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { thirdPartySignInAndUp, signOut } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { appCreateAccount, sdkLogin, appLogin } from "../../routes/routes";
import LoadingPage from "./LoadingPage";
import SessionReact, { getUserId } from "supertokens-auth-react/recipe/session";
import Session from "supertokens-web-js/recipe/session";
import { profile3 } from "../../api/services/apiService";
import { logo_square_without_name } from "../../assets";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { set } from "react-hook-form";

const WebGoogleAuth = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [doesSessionExistState, setDoesSessionExistState] = useState(false);
    const [userId, setUserId] = useState("");
    const [jwt, setJwt] = useState("");
    const [userData, setUserData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();



    async function checkForNameAndOrganisation() {
        try {
            const profileData = await profile3();
            console.log("Profile data is", profileData);
            if (
                !(profileData.name && profileData.name.length >= 0) ||
                !profileData.organisation
            ) {
                console.log("Either Name or Organisation not Present!!! Navigating to create account");
                return false;
            } else {
                console.log("Name and Organisation are present");
                return true;
            }
        } catch (error) {
            console.error("Error fetching profile data:", error);
        }
    }

    async function handleThirdPartyAuthCallback() {
        try {
            console.log("Inside handleThirdPartyAuthCallback");
            const response = await thirdPartySignInAndUp();
            console.log("Response from API signinup is", response);
            if (response.status === "OK") {
                setUser(response.user);
                if (
                    response.createdNewRecipeUser &&
                    response.user.loginMethods.length === 1
                ) {
                    console.log("New user created");
                } else {
                    console.log("User logged in");
                }

                if (await SessionReact.doesSessionExist()) {
                    setDoesSessionExistState(true);
                    let userId = await Session.getUserId();
                    let jwt = await Session.getAccessToken();
                    let accessTokenPayload = await Session.getAccessTokenPayloadSecurely();
                    setUserData(accessTokenPayload);
                    console.log("User Data is", accessTokenPayload);

                    setUserId(userId);
                    setJwt(jwt);

                    console.log("User ID is", userId);
                    console.log("JWT is", jwt);

                
                    if (await checkForNameAndOrganisation()) {
                        handleGoToDashboard();
                    } else {
                        navigate(`${appCreateAccount}?source=web`);
                    }
                }

            } else if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
                // this can happen due to automatic account linking. Please see our account linking docs
            } else {
                toast.error(
                    "No email provided by social login. Please use another form of login"
                );
                window.location.assign(sdkLogin); // redirect back to login page
            }
        } catch (err) {
            console.error("Error in handleThirdPartyAuthCallback:", err);
            if (err.isSuperTokensGeneralError === true) {
                toast.error(err.message);
            } else if (err.status === 409) {
                toast.error("Email already exists!");
                navigate(appLogin, {
                    state: {
                        message:
                            "It appears that you've previously logged in using a different method",
                        source: "third party",
                    },
                });
            } else {
                console.log("Error in handleThirdPartyAuthCallback: Redirecting to login");
                navigate(appLogin, {
                    state: {
                        message: "Oops! Something went wrong.",
                        source: "third party",
                    },
                });
                toast.error("Oops! Something went wrong.");
            }
        } finally {
            setLoading(false); // Set loading to false regardless of success or failure
        }
    }

    useEffect(() => {
        async function checkSession() {
            if (await SessionReact.doesSessionExist()) {
                setDoesSessionExistState(true);
                let userId = await Session.getUserId();

                let accessTokenPayload = await Session.getAccessTokenPayloadSecurely();
                setUserData(accessTokenPayload);
                console.log("User Data is", accessTokenPayload);

                let jwt = await Session.getAccessToken();
                setUserId(userId);
                setJwt(jwt);
                console.log("User ID is", userId);
                console.log("JWT is", jwt);

                if (await checkForNameAndOrganisation()) {
                    navigate('/account/billing');
                } else {
                    navigate(`${appCreateAccount}?source=web`);
                }
            } else {
                console.log("Google Callback");
                try{
                handleThirdPartyAuthCallback();
                }
                catch (error) {
                    console.error("Error in Google Callback:", error);
                    navigate(appLogin);
                }
            }
        }
        checkSession();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const id = await getUserId();
                console.log("Fetched User ID is", id);
                setUserId(id);
            } catch (error) {
                console.error("Error fetching user ID:", error);
            }
        })();
    }, []);

    const handleGoToDashboard = () => {
        navigate('/account/billing');
        window.location.reload();
    };


    if (loading) {
        return <LoadingPage />;
    }

    
};

export default WebGoogleAuth;