import { client1, client2, client3, client4, doqfy_logo, flipkart_logo, ken42_logo, microsoft_logo, optimile_logo } from "../../../../assets";

export const clientsData = [
  {
    id: 1,
    name: "Cliente 1",
    icon: "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1711459222/maximWhite_v5o3ek.png",
    styleClass : "w-24 object-contain h-16 my-auto max-w-none grayscale"
  },
  {
    id: 2,
    name: "Cliente 2",
    icon: "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1711461949/Enitiate-removebg_tp6jow.png",
    styleClass : "w-24 object-contain h-16 my-auto max-w-none grayscale"
  },
  {
    id: 3,
    name: "Cliente 3",
    icon: "https://res.cloudinary.com/dxz5tvv3q/image/upload/v1711459222/SkilleWhite_iaa6oe.png",
    styleClass : "w-24 object-contain h-16 my-auto max-w-none grayscale"
  },
  {
    id: 4,
    name: "Cliente 4",
    icon: doqfy_logo,
    styleClass : "w-24 object-contain h-16 my-auto max-w-none grayscale"
  },
  {
    id: 5,
    name: "Cliente 5",
    icon: ken42_logo,
    styleClass : "w-24 object-contain h-16 my-auto max-w-none grayscale"
  },
  {
    id: 6,
    name: "Cliente 6",
    icon: optimile_logo,
    styleClass : "w-24 object-contain h-16 my-auto max-w-none grayscale"
  },
  {
    id: 7,
    name: "Cliente 7",
    icon: flipkart_logo,
    styleClass : "w-32 object-contain h-24 my-auto max-w-none grayscale"
  },
  {
    id: 8,
    name: "Cliente 8",
    icon: microsoft_logo,
    styleClass : "w-32 object-contain h-24 my-auto max-w-none grayscale"
  },
];
