import React, { useEffect, useState } from "react";

import { initializePaddle } from "@paddle/paddle-js";
import { FaDollarSign } from "react-icons/fa6";
import { getEmailFromSession } from "../../../utils/utlilityFunctions/GetEmailFromSessions";
import { getBillingDetails, postTransaction, authTeest } from "../../../api/services/apiService";
import PlanCard from "./components/PlanCard";
import { formatDate } from "../../../utils/utlilityFunctions/formatDate";
import { formatNextMonth } from "../../../utils/utlilityFunctions/formatNextMonth";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { BsQuestionSquare } from "react-icons/bs";
import DropdownComponent from "../../../pages/AppLandingPage/PricingPage/Dropdown";
import { useNavigate } from "react-router-dom";
import { appSubmitForm } from "../../../routes/routes";
import { useLocation } from "react-router-dom";
import axios from "axios";

function Billing({ logFrom }) {

  const navigate = useNavigate();
  const location = useLocation();
  const [pricingPlanFrequency, setPricingPlanFrequency] = useState("YEARLY");

  const parallelRuns = [
    {
      numberOfRuns: 2,
      price: 250,
      cloudRuns: 5000,
      planName: "PRO",
    },
    {
      numberOfRuns: 3,
      price: 300,
      cloudRuns: 6000,
      planName: "PRO_3PR"
    },
    {
      numberOfRuns: 4,
      price: 350,
      cloudRuns: 7000,
      planName: "PRO_4PR"
    },
    {
      numberOfRuns: 5,
      price: 400,
      cloudRuns: 8000,
      planName: "PRO_5PR"
    },
    {
      numberOfRuns: 8,
      price: 550,
      cloudRuns: 9000,
      planName: "PRO_8PR"
    },
    {
      numberOfRuns: 10,
      price: 650,
      cloudRuns: 10000,
      planName: "PRO_10PR"
    },
  ];

  const [billingData, setBillingData] = useState(null);
  const [currentAppPlan, setCurrentAppPlan] = useState();
  const [currentSdkPlan, setCurrentSdkPlan] = useState();
  const [currentAppPlanIndex, setCurrentAppPlanIndex] = useState(0);
  const [paddle, setPaddle] = useState();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(true);
  const { pathname } = location;


  useEffect(() => {
    const fetchBillingData = async (parallelRuns) => {
      try {
        // const response = await fetch(`${process.env.REACT_APP_STUDIO_BASE_URL}/api/v1/accounts/plans/usage`, { withCredentials: true });
        const data = await axios.get(`${process.env.REACT_APP_STUDIO_BASE_URL}/api/v1/accounts/plans/usage`, { withCredentials: true });
        const currentAppPlan = data.data.planUsages.find(plan => plan.planType === 'APP').planName;
        const currentSdkPlan = data.data.planUsages.find(plan => plan.planType === 'SDK').planName;
        setBillingData(data.data);
        setCurrentAppPlan(currentAppPlan);
        setCurrentSdkPlan(currentSdkPlan);
        const planIndex = parallelRuns.findIndex(plan => plan.planName === currentAppPlan);
        if (planIndex !== -1) setCurrentAppPlanIndex(planIndex);
      } catch (error) {
        console.error('Error fetching billing data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBillingData(parallelRuns);
  }, []);

  // console.log('currentAppPlanIndex', currentAppPlanIndex);

  // let currentAppPlanIndexValue = currentAppPlanIndex === null ? 0 : currentAppPlanIndex;

  const parallelRunsObj = parallelRuns[currentAppPlanIndex];


  const handlePriceSubmit = (priceTitle) => {
    const data = {
      title: "Sales",
      description: "Fill the form below to get exclusive price from our sales team",
      src: `https://tally.so/embed/3EXQ5X?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1?tier=${priceTitle}`,
    };
    navigate(appSubmitForm, { state: { data: data } });
  };



  useEffect(() => {
    const getEmail = async () => {
      let email = await getEmailFromSession();
      setEmail(email);
    };
    getEmail();
  }, []);


  useEffect(() => {
    let environment = process.env.REACT_APP_ENV === "local" ? "sandbox" : null;
    let token = process.env.REACT_APP_PADDLE_CLIENT_TOKEN;

    initializePaddle({
      // environment: process.env.ENV === 'local' ? "sandbox" : null,
      environment: environment,
      token: token, //PADDLE CLIENT TOKEN

      eventCallback: function (data) {
        console.log("eventCallback", data);
        let payload = {};
        // Make an API call to your server for create or update transaction
        if (data.name !== "checkout.payment.failed") {
          payload = {
            transactionId: data.data.transaction_id,
            status: data.data.status,
            email: data.data.customer.email,
            priceId: data.data.items[0].price_id,
            customerId: data.data.customer.id,
            priceName: data.data.items[0].price_name,
            productId: data.data.items[0].product.id
          };
        }
        else {
          console.log("Payment failed");
          payload = {
            transactionId: data.data.transaction_id,
            status: 'failed',
            email: data.data.customer.email,
            priceId: data.data.items[0].price_id,
            customerId: data.data.customer.id,
            priceName: data.data.items[0].price_name,
            productId: data.data.items[0].product.idcu
          };

        }
        postTransaction(payload)
          .then((res) => {
            // window.location.reload();
            console.log("Transaction Post Success", res);
          })
          .catch((err) => {
            console.log("Transaction Post Error", err);
          });

        if (data.name === "checkout.completed") {
          // window.location.reload();
          console.log("Checkout completed");
        }
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  const handleBasicPlan = () => {
    paddle?.Checkout.open({
      discountCode: pricingPlanFrequency === "YEARLY" ? "PH6MONTHS" : "",
      settings: {
        theme: "dark",
      },
      customer: {
        email: email,
      },
      items: [
        {
          priceId: process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_BASIC,
          quantity: 1,
        },
      ],
    });
  };

  const handleProPlan = () => {
    paddle?.Checkout.open({
      discountCode: pricingPlanFrequency === "YEARLY" ? "PH6MONTHS" : "",
      settings: {
        theme: "dark",
      },
      customer: {
        email: email,
      },
      items: [
        {
          priceId: process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO,
          quantity: 1,
        },
      ],
    });
  };

  const handlePlans = (planName) => {
    try {
      console.log("planName", planName);
      let priceId = "";

      if (pricingPlanFrequency === "YEARLY") {
        if (planName === "PRO") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_YEARLY;
        } else if (planName === "STARTER") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_STARTER_YEARLY;
        } else if (planName === "PRO_3PR") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_3PR_YEARLY;
        } else if (planName === "PRO_4PR") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_4PR_YEARLY;
        } else if (planName === "PRO_5PR") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_5PR_YEARLY;
        } else if (planName === "PRO_8PR") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_8PR_YEARLY;
        } else if (planName === "PRO_10PR") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_10PR_YEARLY;
        }
      }
      else if (pricingPlanFrequency === "MONTHLY") {
        if (planName === "PRO") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO;
        } else if (planName === "STARTER") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_STARTER;
        } else if (planName === "PRO_3PR") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_3PR;
        } else if (planName === "PRO_4PR") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_4PR;
        } else if (planName === "PRO_5PR") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_5PR;
        } else if (planName === "PRO_8PR") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_8PR;
        } else if (planName === "PRO_10PR") {
          priceId = process.env.REACT_APP_PADDLE_PRODUCT_ID_AUTOFLOW_PRO_10PR;
        }
      }

      if (!priceId) {
        throw new Error("Invalid plan name or missing price ID");
      }

      paddle?.Checkout.open({
        discountCode: pricingPlanFrequency === "YEARLY" ? "PH6MONTHS" : "",
        settings: {
          theme: "dark",
        },
        customer: {
          email: email,
        },
        items: [
          {
            priceId: priceId,
            quantity: 1,
          },
        ],
      });
    } catch (error) {
      console.error("Error in handlePlans:", error);
    }
  };

  console.log("billingData", billingData);
  const appPlan = billingData?.planUsages.find(plan => plan.planType === 'APP');
  const sdkPlan = billingData?.planUsages.find(plan => plan.planType === 'SDK');


  return (
    <div className="scroll-smooth flex flex-col space-y-10 mt-10 mb-5 bg-black pt-5 px-2 sm:px-10 w-full font-secondary">
      <div className="pb-10 z-[800] border border-[#5352529a] bg-black rounded-lg h-auto">
        <div className="p-1 sm:p-4 md:p-10">
          <div className="px-2 pt-1">
            <h1 className="text-white text-start text-xl md:text-3xl font-primary font-bold">
              <span className="underline decoration-2 md:decoration-4 decoration-primary-color underline-offset-4">
                Billing
              </span>
            </h1>
            <p className="font-secondary mt-2 text-white/60 text-[10px] sm:text-lg">
              Manage your billing and payment details
            </p>
          </div>
          <div className="flex flex-col mt-8">
            <div className="m-2 md:mb-8">
              <h2 className="text-white  sm:text-xl font-primary font-bold mb-1 md:mb-4">
                Current Subscription:
              </h2>

              {/* {billingData && billingData.detail !== "Free membership" && (
                <p className="font-secondary text-white text-xs sm:text-base">
                  {`Your account is billed ${billingData &&
                    (billingData.billing_cycle
                      ? billingData.billing_cycle === "1month"
                        ? "monthly"
                        : billingData.billing_cycle
                      : "monthly")
                    } and next payment is due to`}{" "}
                  <span className="font-bold text-primary-color">
                    {billingData &&
                      (billingData.subscription.next_billing_at
                        ? formatDate(billingData.subscription.next_billing_at)
                        : formatNextMonth)}
                  </span>
                </p>
              )} */}

            </div>

            {billingData ? (
              location.pathname === '/sdk/account/billing' ? (
                <PlanCard
                  title={sdkPlan.displayName}
                  description={sdkPlan.planDescription}
                  price={"$" + sdkPlan.planPrice}
                  tenure="month"
                  invocationsUsed={sdkPlan.currentUsage}
                  totalInvocations={sdkPlan.maxUsage}
                  cancel_subscription_url={sdkPlan.subscriptionDetails?.cancelSubscriptionUrl}
                  billing_cycle={sdkPlan.subscriptionDetails?.billingCycle}
                  next_billing_at={sdkPlan.subscriptionDetails?.nextBillingAt}
                  update_payment_url={sdkPlan.subscriptionDetails?.updatePaymentUrl}
                  handleClick={handleBasicPlan}
                  trigger="invocations"

                />
              ) : (
                <PlanCard
                  title={appPlan.displayName}
                  description={appPlan.planDescription}
                  price={"$" + appPlan.planPrice}
                  tenure="month"
                  invocationsUsed={appPlan.currentUsage}
                  totalInvocations={appPlan.maxUsage}
                  cancel_subscription_url={appPlan.subscriptionDetails?.cancelSubscriptionUrl}
                  billing_cycle={appPlan.subscriptionDetails?.billingCycle}
                  next_billing_at={appPlan.subscriptionDetails?.nextBillingAt}
                  update_payment_url={appPlan.subscriptionDetails?.updatePaymentUrl}
                  handleClick={handleProPlan}
                  trigger="Cloud Runs"
                />
              )
            ) : (
              <span className="loading loading-bars loading-lg text-warning ml-2"></span>
            )}
          </div>
          <div className="border-b border-[#5352529a] my-10" />
          {
            location.pathname === '/account/billing' ? (
              <div className="mt-16">
                <div className="font-medium w-fit z-20 relative flex items-center gap-2 mx-auto text-body-lg bg-[#464646] rounded-full p-1 pr-2">
                  <div className="absolute top-0 left-0 translate-y-[1px] z-10 bg-[#292929] shadow-lg rounded-full w-full h-full"></div>
                  <button onClick={()=>setPricingPlanFrequency("YEARLY")} className={`${ pricingPlanFrequency === "YEARLY" ? "bg-[hsla(39,75%,45%,1)]" : "" } z-20 px-3 pr-1.5 py-1 cursor-pointer text-white/90 text-center grow rounded-full flex justify-between gap-2 items-center`}>
                    Yearly
                    <span className={`text-[11px]  font-medium whitespace-nowrap ${ pricingPlanFrequency === "YEARLY" ? " text-[rgba(63,14,16,1)] bg-gradient-to-b from-[#251d0d65] to-50% to-[hsla(39,100%,50%,1)] " : " text-white bg-[#464646] " } px-2 py-0.5 rounded-full`}>
                      1 MONTH FREE 🚀
                    </span>
                  </button>
                  <button onClick={()=>setPricingPlanFrequency("MONTHLY")} className={`${ pricingPlanFrequency === "MONTHLY" ? "bg-[hsla(39,75%,45%,1)]" : "" } z-20 px-3 py-1 cursor-pointer text-white/90 text-center rounded-full flex justify-between gap-1 items-center`}>Monthly</button>
                </div>
                <div
                  id="plans"
                  className="mx-auto mt-8 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 lg:gap-y-0 lg:max-w-none lg:grid-cols-4"
                >
                  {/* card0 */}
                  <div className="bg-black/40 sm:mx-8 lg:mx-0 rounded-t-3xl  lg:rounded-bl-3xl lg:rounded-tr-none lg:rounded-br-none rounded-3xl p-8 ring-1 ring-zinc-800 backdrop-blur-md">

                    {currentAppPlan === 'FREE' && (
                      <div className="absolute top-6 right-6 text-black badge bg-purple-600 badge-md gap-2 font-heading font-medium py-1 h-fit">
                        ✦ Active
                      </div>
                    )}


                    <h4 className="text-zinc-200 text-3xl font-display font-semibold leading-loose">
                      Basic
                    </h4>
                    <div className="text-zinc-200 mt-4 flex items-baseline gap-x-2">
                      <h1 className="text-5xl font-semibold tracking-tight">
                        Free Forever
                      </h1>
                    </div>
                    <p className="text-zinc-400 mt-6 text-sm">
                      For testing simple web apps and learn about the platform
                    </p>
                    <ul className="text-zinc-400 mt-8 space-y-3 text-sm font-display">
                      <li className="flex font-semibold items-center gap-x-3">
                        What's included:
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline /> 1 User
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        Unlimited Tests
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        Unlimited Test Suites
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        Unlimited Local Test Runs
                      </li>
                      <div class="tooltip" data-tip="Get a screenshot of every test step in your test runs">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline />
                          Screenshots <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        7 Days of Test Run History
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        Community & Email Support
                      </li>
                    </ul>
                    <a
                      href="/downloads"
                      className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-zinc-400 hover:border-zinc-500/80"
                    >
                      Download App
                    </a>
                  </div>
                  {/* card 2 */}
                  <div className="relative bg-black/40 sm:mx-8 lg:mx-0 rounded-t-3xl  lg:rounded-bl-3xl lg:rounded-tr-none lg:rounded-br-none rounded-3xl px-8 py-14 ring-1 ring-zinc-800 backdrop-blur-md">

                    {/* {currentAppPlan === 'STARTER' && ( */}
                    <div className="absolute top-6 right-6 badge badge-warning badge-md gap-2 font-heading font-medium py-1 h-fit">
                      {pricingPlanFrequency === "YEARLY" ? "✦ 1 Month FREE" : "✦ 7 Days FREE"}
                    </div>
                    {/* )} */}

                    <h4 className="text-zinc-200 text-3xl font-display font-semibold leading-loose">
                      Starter
                    </h4>
                    <div className="text-zinc-200 mt-4 flex items-baseline gap-x-2">
                      <h1 className="text-5xl font-semibold tracking-tight">
                        ${pricingPlanFrequency === "YEARLY"
                        ? "0"
                        : "99"}
                      </h1>
                      <span className="font-heading">/ month</span>
                    </div>
                    <span className={`${ pricingPlanFrequency === "YEARLY" ? " visible " : " invisible " } font-heading text-sm text-zinc-400`}>$49/month from 2nd month onwards</span>
                    <p className="text-zinc-400 mt-6 text-sm">
                      For testing simple web apps and learn about the platform
                    </p>
                    <ul className="text-zinc-400 mt-8 space-y-3 text-sm font-display">
                      <li className="flex font-semibold items-center gap-x-3">
                        Everything in FREE plus:
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        100 Cloud Runs
                      </li>
                      <div class="tooltip" data-tip="Get a video recording of every test run!">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline />
                          Video Recordings <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="Schedule your tests to run after a certain interval">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline /> Scheduled Runs <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="Get instant reports and notifications of your test runs">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline /> Slack Notifications <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="Get instant email to all stakeholders">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline /> Email Notifications <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="Seamlessly switch between environments to test multiple environments">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline /> Environments <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        30 Days of Test History
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        Community & Email Support
                      </li>
                    </ul>
                    <button
                      onClick={() => handlePlans("STARTER")}
                      className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-zinc-400 hover:border-zinc-500/80"
                    >
                      Start free 14-day trial
                    </button>
                  </div>
                  {/* card 3 */}
                  <div className="relative bg-primary-color-light/90 rounded-3xl p-8 py-6 ring-zinc-800 backdrop-blur-md z-20  border-[5px] border-[#F29D20] shadow-[0px_0px_15px_-3px] shadow-[#F29D20]">
                    <div className="absolute top-6 right-6 flex lg:flex-col lg:items-end gap-2">
                      {/* <div className="badge badge-warning badge-md gap-2 font-heading font-medium py-1 h-fit">
                      ✦ Most popular
                    </div> */}
                    </div>
                    {/* {currentAppPlan && currentAppPlan.includes('PRO') && ( */}
                      <div className="absolute top-6 right-6 badge badge-warning badge-md gap-2 font-heading font-medium py-1 h-fit">
                        {pricingPlanFrequency === "YEARLY" ? "✦ 1 Month FREE" : "✦ 7 Days FREE"}
                      </div>
                    {/* )} */}


                    <h4 className="text-zinc-950 text-3xl font-display font-semibold leading-loose">
                      Pro
                    </h4>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <h1 className="text-zinc-950 text-5xl font-semibold tracking-tight">
                        ${pricingPlanFrequency === "YEARLY"
                        ? 0
                        : parallelRunsObj?.price}
                      </h1>
                      <span className="text-zinc-400 font-heading">/ month</span>
                    </div>
                    <span className={`${ pricingPlanFrequency === "YEARLY" ? " visible " : " invisible " } font-heading text-sm text-zinc-700`}>${parallelRunsObj?.price}/month from 2nd month onwards</span>
                    <p className="text-zinc-400 mt-6 text-sm">
                      Everything you need to get started with testing, perfect for
                      individuals and small teams.
                    </p>
                    <ul className="text-zinc-400 mt-8 space-y-3 text-sm font-display">
                      <li className="flex font-semibold items-center gap-x-3">
                        Everything in STARTER plus:
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline /> 5 Users
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline /> {parallelRunsObj.cloudRuns} Cloud Test Runs
                      </li>
                      <DropdownComponent parallelRuns={parallelRuns} setParallelRunsObjIndex={(index) => setCurrentAppPlanIndex(index)} parallelRunsObj={parallelRunsObj} />
                      <br></br>
                      <div class="tooltip" data-tip="One-click CI/CD integration. We even help with the integrations!">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline /> CI/CD Integrations <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="Get temporary emails and AI powered email detection for testing sign up flows">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline /> Inbox for e-mail testing <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="Webhooks to customize the alerts you receive">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline /> Webhooks <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="Get exclusive API access for all your testing needs">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline /> API Access <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="Gain fine-tuned control over your organisation">
                        <li className="flex items-center gap-x-3 text-left">
                          <IoCheckmarkDoneOutline /> Role-based Access Control <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="increase productivity with AutoFlow AI suggestions">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline />
                          500 AI Suggestions <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="Add robust and self-healing AI steps to your test cases">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline />
                          1000 AI Steps <BsQuestionSquare />
                        </li>
                      </div>
                      <br />
                      <div class="tooltip" data-tip="Call or message us anytime for immediate problem resolution">
                        <li className="flex items-center gap-x-3">
                          <IoCheckmarkDoneOutline />
                          24/7 Support <BsQuestionSquare />
                        </li>
                      </div>
                    </ul>
                    <button
                      onClick={() => handlePlans(`${parallelRunsObj.planName}`)}
                      className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-black font-bold hover:border-zinc-500/80"
                    >
                      Subscribe Now
                    </button>
                  </div>

                  {/* card 4 */}
                  <div className="bg-black/40 sm:mx-8 lg:mx-0 rounded-t-3xl  lg:rounded-br-3xl lg:rounded-tl-none lg:rounded-bl-none rounded-3xl px-8 py-14 ring-1 ring-zinc-800 backdrop-blur-md">
                    <div className="flex flex-row items-center justify-between">
                      <h1 className="text-zinc-200 text-3xl font-display font-semibold leading-loose">
                        Enterprise
                      </h1>
                    </div>
                    <div className="text-zinc-200 mt-4 flex items-baseline gap-x-2">
                      <h1 className="text-5xl font-semibold tracking-tight">
                        Talk to Us
                      </h1>
                      {/* <span className="text-zinc-600 font-heading">/ month</span> */}
                    </div>

                    <p className="text-zinc-400 mt-6 text-sm">
                      Crafted for teams and enterprises seeking an unparalleled,
                      privacy-friendly testing experience.
                    </p>
                    <ul className="text-zinc-400 mt-8 space-y-3 text-sm font-display">
                      <li className="flex font-semibold items-center gap-x-3">
                        Everything in BASIC plus:
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline /> Up to 48x faster testing via parallel test execution
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline /> Custom Seat Numbers
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        Up to Unlimited test history
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        Exclusive Customer Support
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        On-Premise Deployment
                      </li>
                      <li className="flex items-center gap-x-3">
                        <IoCheckmarkDoneOutline />
                        Customization Support for specific use-cases
                      </li>
                    </ul>
                    <button
                      onClick={() => handlePriceSubmit("Pro")}
                      className="relative mt-8 inline-flex w-full flex-shrink-0 items-center justify-center rounded-xl border px-3 py-2 font-heading text-sm font-medium transition border-zinc-500/50 bg-zinc-600/10 text-zinc-400 hover:border-zinc-500/80"
                    >
                      Contact Sales
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="py-4 flex flex-col sm:flex-row justify-center sm:justify-start gap-8">
                {/* {billingData ? (
              billingData.detail === "Free membership" && )}
               */}
                <div className="card border border-[#5352529a] w-full sm:w-96 relative">
                  {billingData && billingData.detail === "Paid membership" && (
                    <p className="absolute top-2 right-2 text-white border ml-2 px-2 py-[2px] rounded-xl bg-secondary-color font-bold heading shadow-top-left-bottom-right">
                      Current Plan
                    </p>
                  )}

                  <div className="card-body">
                    <h2 className="card-title text-primary-color text-xl sm:text-2xl font-bold">
                      AutoFlow Basic
                    </h2>
                    <p className="font-secondary font-light text-sm sm:text-base text-white/55">
                      for Business and start ups
                    </p>
                    <p className="sm:text-2xl font-bold mb-2 mt-5 flex flex-col">
                      <div className="flex flex-row items-center text-white">
                        <FaDollarSign />
                        <span className="text-4xl sm:text-6xl join">50</span>
                      </div>
                      <div className="text-sm sm:text-base px-2 text-white">
                        / month
                      </div>
                    </p>

                    <div className="card-actions py-2 justify-start">
                      <button
                        onClick={
                          billingData && billingData.detail === "Paid membership"
                            ? null
                            : handleBasicPlan
                        }
                        className={`btn btn-sm w-40 btn-outline btn-warning sm:btn-md font-secondary ${billingData &&
                          billingData.detail === "Paid membership" &&
                          "cursor-not-allowed hover:bg-black no-animation"
                          }`}
                      >
                        {billingData && billingData.detail === "Free membership"
                          ? "Buy now"
                          : "Explore"}
                      </button>
                    </div>
                  </div>
                  <div className="bg-gray-800 h-32 p-4 px-8 rounded-b-2xl">
                    <h3 className="text-white font-primary font-bold mb-1">
                      Features:
                    </h3>

                    <p className="mb-1 text-sm sm:text-base font-semibold text-white">
                      - 5,000{" "}
                      <span className="text-primary-color font-semibold">
                        autoflow()
                      </span>{" "}
                      invocations
                    </p>
                    <p className="mb-1 text-sm sm:text-base font-semibold text-white">
                      - 5 users
                    </p>
                  </div>
                </div>

                <div className="card border border-[#5352529a] w-full sm:w-96">
                  <div className="card-body">
                    <h2 className="card-title text-primary-color text-xl sm:text-2xl font-bold">
                      AutoFlow Pro
                    </h2>
                    <p className="font-secondary font-light text-sm sm:text-base text-white/55">
                      for large Business and Enterprises
                    </p>
                    <p className="text-2xl sm:text-5xl font-bold mb-2 mt-5 flex flex-col">
                      <div className="flex flex-row items-center text-white">
                        Let's talk
                      </div>
                    </p>

                    <div className="card-actions justify-start py-2">
                      <button
                        onClick={() => {
                          window.open(
                            `https://mail.google.com/mail/?view=cm&fs=1&tocontact@autoflowapp.com}`,
                            "_blank"
                          );
                        }}
                        className="btn btn-sm w-40 btn-outline btn-warning sm:btn-md font-secondary"
                      >
                        Contact Sales
                      </button>
                    </div>
                  </div>
                  <div className="bg-gray-800 h-32 p-4 px-8 rounded-b-2xl">
                    <h3 className="text-white font-primary font-bold mb-1">
                      Features:
                    </h3>

                    <p className="mb-1 text-sm sm:text-base font-semibold text-white">
                      - Custom{" "}
                      <span className="text-primary-color font-semibold">
                        autoflow()
                      </span>{" "}
                      invocations
                    </p>
                    <p className="mb-1 text-sm sm:text-base font-semibold text-white">
                      - Unlimited users
                    </p>
                  </div>
                </div>
              </div>
            )
          }

        </div>
      </div>
    </div>
  );
}

export default Billing;



// Completion Data 