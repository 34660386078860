import { rgbaToHsla } from "./rgbaToHsla";
export function getColorFromTailwindClass(className, property = null) {
    // Create a temporary DOM element
    const tempEl = document.createElement("div");
    // Add the Tailwind class
    tempEl.className = className;
    // Append the element to the body (it won't be visible)
    document.body.appendChild(tempEl);
    // Get the computed styles of the element
    const style = window.getComputedStyle(tempEl);
    // Define a list of color-related properties
    const colorProperties = ['color', 'backgroundColor', 'borderColor', 'outlineColor'];
    // If a specific property is requested, check only that one
    let colorValue;
    if (property) {
        colorValue = style[property];
    } else {
        // Otherwise, find the first non-empty color property
        colorValue = colorProperties.find(prop => style[prop]);
    }
    document.body.removeChild(tempEl);
    // Extract the 'color' or 'background-color' property
    const rgbaColor = colorValue || "rgba(0, 0, 0, 0)";
    // Remove the temporary element from the DOM
    // Convert the RGBA color to HSLA
    return rgbaToHsla(rgbaColor);
}