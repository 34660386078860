import React, { useState } from 'react';
import thumpnail from '../../../../assets/images/AllScreen.png'


function VideoWithPreview() {
  const [showIframe, setShowIframe] = useState(false);

  const toggleIframe = () => {
    setShowIframe(!showIframe);
  };

  return (
    <div className="video-responsive w-full h-full">
      {showIframe ? (
        <iframe
          className="w-full h-full aspect-auto object-cover"
          height='720'
          src="https://www.youtube.com/embed/FPj8ywTktMQ?si=f_QaHUbv8k4Tj-iJ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowFullScreen
        >
        </iframe>
      ) : (
        <div onClick={toggleIframe} className="relative w-full h-full rounded-lg md:rounded-xl overflow-hidden">
          <div className="w-full h-full bg-gradient-to-b from-transparent to-black absolute bottom-0"></div>
          <img
            className="w-full h-full object-fit"
            src={thumpnail}
            alt="Video Preview"
            onClick={toggleIframe}
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div onClick={toggleIframe} className="w-20 h-20 bg-[#ffe4c4] rounded-full flex items-center justify-center hover:bg-yellow-600  hover:animate-none transition transform animate-pulse  duration-300">
              <svg className="w-8 h-8 text-gray-800 transform transition-transform hover:scale-110" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3l14 9L5 21V3z" />
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoWithPreview;
