import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdInfoOutline, MdPerson } from "react-icons/md";
import Tooltip from "../../../components/Tooltip";
import { SecondaryButton } from "../../../components/Buttons";


const PricingCalculator = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [ navBarHeight, setNavBarHeight ] = useState(0);

    const getQueryParams = (search) => {
        return Object.fromEntries(new URLSearchParams(search));
    };

    // Parse query parameters when the component mounts
    useEffect(() => {
        const queryParams = getQueryParams(location.search);

        // If query params exist, update the state with the values from the URL
        setX((prevState) => ({
            ...prevState,
            ...queryParams,
            coverage: parseFloat(queryParams.coverage || prevState.coverage),
            timeToCreateEachTest: parseFloat(queryParams.timeToCreateEachTest || prevState.timeToCreateEachTest),
            timeToFixFailedTest: parseFloat(queryParams.timeToFixFailedTest || prevState.timeToFixFailedTest),
            failRate: parseFloat(queryParams.failRate || prevState.failRate),
            qaEngineerSalary: parseFloat(queryParams.qaEngineerSalary || prevState.qaEngineerSalary),
            sdeSalary: parseFloat(queryParams.sdeSalary || prevState.sdeSalary),
        }));
    }, [location.search]);

    useEffect(() => {
        document.title = "calculator | AutoFlow Studio";
    }, [])

    // Default input values
    const [x, setX] = useState({
        numberOfDevelopers: 1,
        coverage: 1,  // Slider
        timeToCreateEachTest: 1,  // Slider
        timeToFixFailedTest: 1,  // Slider
        failRate: 0.075,  // Slider
        qaEngineerSalary: 7000,  // Slider
        numberofSDETs: 1,
        sdeSalary: 10000,  // Slider
        runCadence: 1,
        runsCadencFrequency: "daily",  // Dropdown
        runType: "sequential",  // Dropdown
        costPerSequentialRun: 0.03,
        averageTestRunLengthMinutes: 1,
        numberOfTestsRunInParallel: 1,
        CostForParallelizationOfIndividualTestPerMonth: 100
    });

    // Update URL with query params when state changes
    const updateQueryParams = (newState) => {
        const queryParams = new URLSearchParams(newState).toString();
        navigate(`?${queryParams}`, { replace: true });  // Update URL without refreshing the page
    };

    // Handle changes to inputs
    const handleChange = (name, value) => {
        const newState = { ...x, [name]: value };
        setX(newState);
        updateQueryParams(newState);
    };

    // Pricing logic (use your calculation function here)
    const calculatePricing = () => {
        // Destructure input parameters from state
        const {
            numberOfDevelopers,
            coverage,
            timeToCreateEachTest,
            timeToFixFailedTest,
            failRate,
            qaEngineerSalary,
            numberofSDETs,
            sdeSalary,
            runCadence,
            runsCadencFrequency,
            runType,
            averageTestRunLengthMinutes,
            numberOfTestsRunInParallel,
            costPerSequentialRun,
            CostForParallelizationOfIndividualTestPerMonth,
        } = x;



        // Calculating testForGivenCoverage and timeToCreateAllTestsDays
        const testForGivenCoverage = Math.ceil(numberOfDevelopers * 31 * coverage); // 31*numberOfDevelopers*coverage
        const timeToCreateAllTestsDays = Math.ceil((testForGivenCoverage * timeToCreateEachTest) / 8); // Assuming an 8-hour workday

        // Calculating testRunsPerMonth based on runCadenceFrequency
        let testRunsPerMonth;
        if (runsCadencFrequency === 'daily') {
            testRunsPerMonth = testForGivenCoverage * runCadence * 20; // Assuming 20 working days in a month
        } else if (runsCadencFrequency === 'weekly') {
            testRunsPerMonth = testForGivenCoverage * runCadence * 4; // 4 weeks in a month
        } else if (runsCadencFrequency === 'monthly') {
            testRunsPerMonth = testForGivenCoverage * runCadence;
        }

        // Calculating testFailuresPerMonth and testMaintainenceHoursPerMonth
        const testFailuresPerMonth = Math.ceil(testRunsPerMonth * failRate);
        const testMaintainenceHoursPerMonth = Math.ceil(testFailuresPerMonth * timeToFixFailedTest);

        // Calculating team size and salary
        const numberOfQAEsInTeam = Math.ceil((testForGivenCoverage * coverage) / 100); // Hypothetical formula
        const totalTeamSalary = (qaEngineerSalary * numberOfQAEsInTeam) + (numberofSDETs * sdeSalary);

        // Sequential Run Calculations
        let runningHoursPerYearForSequentialRuns = 0;
        let sequentialRunCostPerYear = 0;
        let totalAnnualCostSequentialRun = 0;
        if (runType === 'sequential') {
            runningHoursPerYearForSequentialRuns = testRunsPerMonth * averageTestRunLengthMinutes * 12 / 60; // Convert minutes to hours
            sequentialRunCostPerYear = testRunsPerMonth * costPerSequentialRun * 12; // Annual cost of sequential runs
            totalAnnualCostSequentialRun = totalTeamSalary + sequentialRunCostPerYear;
        }

        // Parallel Run Calculations
        let runningHoursPerYearForParallelRuns = 0;
        let parallelRunCostPerYear = 0;
        let totalAnnualCostParallelRun = 0;
        if (runType === 'parallel') {
            runningHoursPerYearForParallelRuns = Math.ceil(testRunsPerMonth / numberOfTestsRunInParallel); // Adjust for parallelization
            parallelRunCostPerYear = CostForParallelizationOfIndividualTestPerMonth * 12; // Annual parallelization cost
            totalAnnualCostParallelRun = totalTeamSalary + parallelRunCostPerYear;
        }

        return {
            testForGivenCoverage,
            timeToCreateAllTestsDays,
            testRunsPerMonth,
            testFailuresPerMonth,
            testMaintainenceHoursPerMonth,
            numberOfQAEsInTeam,
            numberOFSDETsInTeam: numberofSDETs,
            totalTeamSalary,
            runningHoursPerYearForSequentialRuns,
            sequentialRunCostPerYear,
            totalAnnualCostSequentialRun,
            runningHoursPerYearForParallelRuns,
            parallelRunCostPerYear,
            totalAnnualCostParallelRun
        };
    };

    const getCostCard = () =>{
        return (
            <div className="bg-alpha-700 rounded-lg p-6 h-fit">
                <div className='flex flex-col gap-2 justify-between w-full'>
                    <div className='flex flex-col gap-2'>
                        <div className='flex flex-col gap-2 text-[#fff]/75 font-normal text-xl'>
                            <span>Total cost of E2E testing </span>
                            <div className="flex gap-1">
                                <span className='bg-secondary-red-primary rounded px-1.5'> without </span>
                                <span>Autoflow</span>
                            </div>
                        </div>
                        <span className='flex gap-2 text-secondary-red-primary font-inter font-bold text-2xl'>~${x.runType === 'sequential' ? result.totalAnnualCostSequentialRun : result.totalAnnualCostParallelRun} <span className='font-inter'> / year</span></span>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div className='flex flex-col gap-2 text-[#fff]/75 font-normal text-xl'>
                            <div className="flex gap-1">
                                <span className='bg-secondary-green-primary rounded px-1.5'> with </span>
                                <span>Autoflow</span>
                            </div>
                        </div>
                        <div class="flex items-center space-x-2">
                            <span class="relative text-lg text-gray-500 font-semibold line-through transition duration-300 ease-in-out hover:text-red-600">
                                <span class="absolute inset-0 h-0.5 bg-red-600 origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>
                                ${x.runType === 'sequential' ? result.totalAnnualCostSequentialRun : result.totalAnnualCostParallelRun}
                            </span>
                            <span class="font-robotomono text-2xl text-border-selected font-bold whitespace-nowrap">
                                ~${ ((result.testRunsPerMonth * 12) / 1000 ) * 50 }<span className='font-inter ml-1'>/ year</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const result = calculatePricing();

    useEffect(()=>{

        setNavBarHeight(document.getElementById("navBar").offsetHeight);

        window.addEventListener("resize", ()=>{
            setNavBarHeight(document.getElementById("navBar").offsetHeight);
        })
    }, [])

    return (
        <div className='pt-24 md:pt-32 pb-24 md:pb-32 md:px-24 px-5'>
            <div className='flex flex-col gap-4'>
                <p className='bg-gradient-to-br from-zinc-50 to-zinc-600 bg-clip-text text-transparent text-center font-plus-jakarta-sans text-base font-normal leading-normal '>Calculator</p>
                <p className='bg-gradient-to-r from-white via-white/80 to-white/30 bg-clip-text text-transparent text-center font-plus-jakarta text-4xl font-medium leading-[46px]'>True value for your testing</p>
                <p className='text-neutral-200 text-center font-inter text-sm font-medium leading-5'>Compare the cost of in-house end to end testing against Autoflow</p>
            </div>

            <div className="flex flex-col sm:flex-row gap-2 sm:justify-center">

                <div className='flex flex-col md:flex-row gap-2 pt-10'>
                    <div className='flex flex-col gap-2 md:w-[40%] w-[100%] overflow-y-auto font-inter'>
                        <div className='flex flex-col gap-2  p-4 rounded-lg bg-alpha-700  h-fit'>
                            <div className='w-full text-neutral-200 font-heading text-sm font-medium leading-[1.4285]'>Coverage & Test Creation</div>
                            <div className='flex gap-2 w-full'>
                                <span className='text-[#fff]/75'>
                                    Team size:
                                </span>
                                <input
                                    type='number'
                                    className='w-[20%] text-text-subtlest rounded bg-Surface-Raised-Primary border border-[#C3DEFE33]/20 focus:border-[#C3DEFE33]/20 px-1'
                                    min={1}
                                    value={x.numberOfDevelopers}
                                    onChange={(e) => handleChange('numberOfDevelopers', e.target.value)}
                                />
                                <span className='text-neutral-200 font-inter text-sm font-medium leading-[20px]'>
                                    developers
                                </span>
                            </div>
                            <div className='text-text-subtlest font-inter text-xs font-normal leading-[16px]'>
                                Team size is a good indicator of your app’s testing footprint.
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p className='text-[#FFF] font-inter text-base font-normal leading-[24px]'>{`Coverage`}</p>
                                <p className='text-border-selected font-robotomono font-normal leading-[20px]'>{`${(x.coverage * 100).toFixed(0)}%`}</p>
                                <div className='relative'>
                                    {/* <div
                                        className="absolute top-1/2 h-2 bg-yellow-500 rounded-full"
                                        style={{ width: `${(x.coverage * 100).toFixed(0)}%` }}
                                    ></div> */}
                                    <input
                                        type="range"
                                        min={0.1}
                                        max={1}
                                        step={0.01}
                                        value={x.coverage}
                                        onChange={(e) => handleChange('coverage', e.target.value)}
                                        className="
                                        w-full
                                        h-2
                                        bg-selected-shadow
                                        rounded-lg
                                        appearance-none
                                        cursor-pointer
                                        accent-border-selected
                                        accent-w-10
                                        accent-h-10
                                        focus:outline-none
                                    "
                                    />
                                </div>
                                <div className='text-text-subtlest font-inter text-xs font-normal leading-[16px]'>
                                    Aiming for 80% E2E test coverage ensures critical paths are well-tested, significantly reducing the risk of undetected bugs.
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p className='text-[#FFF] font-inter text-base font-normal leading-[24px]'>{`Time to Create Each Test (hours)`}</p>
                                <p className='text-border-selected font-robotomono font-normal leading-[20px]'>{`${x.timeToCreateEachTest} hours`}</p>
                                <div className='relative'>
                                    {/* <div
                                        className="absolute top-1/2 h-2 bg-yellow-500 rounded-full"
                                        style={{ width: `${(x.coverage * 100).toFixed(0)}%` }}
                                    ></div> */}
                                    <input
                                        type="range"
                                        min={0.1}
                                        max={5}
                                        step={0.1}
                                        value={x.timeToCreateEachTest}
                                        onChange={(e) => handleChange('timeToCreateEachTest', e.target.value)}
                                        className="
                                        w-full
                                        h-2
                                        bg-selected-shadow
                                        rounded-lg
                                        appearance-none
                                        cursor-pointer
                                        accent-border-selected
                                        accent-w-10
                                        accent-h-10
                                        focus:outline-none
                                    "
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2  p-4 rounded-lg bg-alpha-700  h-fit'>
                            <div className='w-full text-neutral-200 font-heading text-sm font-medium leading-[1.4285]'>Run Cadence & Failures</div>
                            <div className='flex gap-2 w-full'>
                                <select className='text-text-subtlest rounded bg-Surface-Raised-Primary border border-[#C3DEFE33]/20 focus:border-[#C3DEFE33]/20 px-1' value={x.runsCadencFrequency} onChange={(e) => handleChange('runsCadencFrequency', e.target.value)}>
                                    <option className='text-text-subtlest hover:bg-selected-shadow' value="daily">Daily</option>
                                    <option className='text-text-subtlest hover:bg-selected-shadow' value="weekly">Weekly</option>
                                    <option className='text-text-subtlest hover:bg-selected-shadow' value="monthly">Monthly</option>
                                </select>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p className='text-[#FFF] font-inter text-base font-normal leading-[24px]'>{`Fail Rate`}</p>
                                <p className='text-border-selected font-robotomono font-normal leading-[20px]'>{`${(x.failRate * 100).toFixed(1)}%`}</p>
                                <div className='relative'>
                                    {/* <div
                                        className="absolute top-1/2 h-2 bg-yellow-500 rounded-full"
                                        style={{ width: `${(x.coverage * 100).toFixed(0)}%` }}
                                    ></div> */}
                                    <input
                                        type="range"
                                        min={0}
                                        max={0.3}
                                        step={0.005}
                                        value={x.failRate}
                                        onChange={(e) => handleChange('failRate', e.target.value)}
                                        className="
                                        w-full
                                        h-2
                                        bg-selected-shadow
                                        rounded-lg
                                        appearance-none
                                        cursor-pointer
                                        accent-border-selected
                                        accent-w-10
                                        accent-h-10
                                        focus:outline-none
                                    "
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p className='text-[#FFF] font-inter text-base font-normal leading-[24px]'>{`QA Engineer Salary ($)`}</p>
                                <p className='text-border-selected font-robotomono font-normal leading-[20px]'>{`$${x.qaEngineerSalary} `}</p>
                                <div className='relative'>
                                    {/* <div
                                        className="absolute top-1/2 h-2 bg-yellow-500 rounded-full"
                                        style={{ width: `${(x.coverage * 100).toFixed(0)}%` }}
                                    ></div> */}
                                    <input
                                        type="range"
                                        min={5000}
                                        max={40000}
                                        step={500}
                                        value={x.qaEngineerSalary}
                                        onChange={(e) => handleChange('qaEngineerSalary', e.target.value)}
                                        className="
                                        w-full
                                        h-2
                                        bg-selected-shadow
                                        rounded-lg
                                        appearance-none
                                        cursor-pointer
                                        accent-border-selected
                                        accent-w-10
                                        accent-h-10
                                        focus:outline-none
                                    "
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2  p-4 rounded-lg bg-alpha-700  h-fit'>
                            <div className='w-full text-neutral-200 font-heading text-sm font-medium leading-[1.4285]'>Team Composition</div>
                            <div className='flex gap-2 w-full'>
                                <span className='text-[#fff]/75'>
                                    Number of SDETs:
                                </span>
                                <input
                                    type='number'
                                    className='px-1 w-[20%] text-text-subtlest rounded bg-Surface-Raised-Primary border border-[#C3DEFE33]/20 focus:border-[#C3DEFE33]/20 px-1'
                                    min={1}
                                    value={x.numberofSDETs}
                                    onChange={(e) => handleChange('numberofSDETs', e.target.value)}
                                />
                            </div>
                            <div className='text-text-subtlest font-inter text-xs font-normal leading-[16px]'>
                                Team size is a good indicator of your app’s testing footprint.
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p className='text-[#FFF] font-inter text-base font-normal leading-[24px]'>{`SDET Salary ($)`}</p>
                                <p className='text-border-selected font-robotomono font-normal leading-[20px]'>{`$${x.sdeSalary}`}</p>
                                <div className='relative'>
                                    {/* <div
                                        className="absolute top-1/2 h-2 bg-yellow-500 rounded-full"
                                        style={{ width: `${(x.coverage * 100).toFixed(0)}%` }}
                                    ></div> */}
                                    <input
                                        type="range"
                                        min={8000}
                                        max={90000}
                                        step={500}
                                        value={x.sdeSalary}
                                        onChange={(e) => handleChange('sdeSalary', e.target.value)}
                                        className="
                                        w-full
                                        h-2
                                        bg-selected-shadow
                                        rounded-lg
                                        appearance-none
                                        cursor-pointer
                                        accent-border-selected
                                        accent-w-10
                                        accent-h-10
                                        focus:outline-none
                                    "
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2  p-4 rounded-lg bg-alpha-700  h-fit'>
                            <div className='w-full text-neutral-200 font-heading text-sm font-medium leading-[1.4285]'>Run Type & Output</div>
                            <div className='flex gap-2 w-full'>
                                <select className='text-text-subtlest px-1 rounded bg-Surface-Raised-Primary border border-[#C3DEFE33]/20 focus:border-[#C3DEFE33]/20' value={x.runType} onChange={(e) => handleChange('runType', e.target.value)}>
                                    <option className='text-text-subtlest hover:bg-selected-shadow' value="sequential">Sequential</option>
                                    <option className='text-text-subtlest hover:bg-selected-shadow' value="parallel">Parallel</option>
                                </select>
                            </div>
                            {x.runType === 'parallel' && (
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-col gap-2 w-full'>
                                        <p className='text-[#fff]/75'>
                                            Number of Tests Run in Parallel:
                                        </p>
                                        <input
                                            type='number'
                                            className='w-[20%] text-text-subtlest rounded bg-Surface-Raised-Primary border border-[#C3DEFE33]/20 focus:border-[#C3DEFE33]/20 px-1'
                                            min={1}
                                            value={x.numberOfTestsRunInParallel}
                                            onChange={(e) => handleChange('numberOfTestsRunInParallel', e.target.value)}
                                        />
                                    </div>
                                    <div className='flex flex-col gap-2 w-full'>
                                        <p className='text-[#fff]/75'>
                                            Cost for parallelization of an individual test:
                                        </p>
                                        <input
                                            type='number'
                                            className='w-[20%] text-text-subtlest rounded bg-Surface-Raised-Primary border border-[#C3DEFE33]/20 focus:border-[#C3DEFE33]/20 px-1'
                                            min={1}
                                            value={x.CostForParallelizationOfIndividualTestPerMonth}
                                            onChange={(e) => handleChange('CostForParallelizationOfIndividualTestPerMonth', e.target.value)}
                                        />
                                    </div>
                                    <div className='text-text-subtlest font-inter text-xs font-normal leading-[16px]'>
                                        Tests run simultaneously across multiple resources. This method can reduce total execution time significantly but might incur higher costs due to the need for more resources.
                                    </div>
                                </div>
                            )
                            }
                            {x.runType === 'sequential' && (
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-col gap-2 w-full'>
                                        <span className='text-[#fff]/75'>
                                            Cost per Sequential Run ($):
                                        </span>
                                        <input
                                            type='number'
                                            className='w-[20%] text-text-subtlest rounded bg-Surface-Raised-Primary border border-[#C3DEFE33]/20 focus:border-[#C3DEFE33]/20 px-1'
                                            min={0.01}
                                            value={x.costPerSequentialRun}
                                            onChange={(e) => handleChange('costPerSequentialRun', e.target.value)}
                                        />
                                    </div>
                                    <div className='flex flex-col gap-2 w-full'>
                                        <span className='text-[#fff]/75'>
                                            Average Test Run Length (minutes):
                                        </span>
                                        <input
                                            type='number'
                                            className='w-[20%] text-text-subtlest rounded bg-Surface-Raised-Primary border border-[#C3DEFE33]/20 focus:border-[#C3DEFE33]/20 px-1'
                                            min={1}
                                            value={x.averageTestRunLengthMinutes}
                                            onChange={(e) => handleChange('averageTestRunLengthMinutes', e.target.value)}
                                        />
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row sm:justify-between gap-2 md:w-[60%] w-[100%]">
                        <div className='flex flex-col gap-2 justify-start font-inter sm:grow'>

                            {/* Test Coverage & Creation Section */}
                            <div className='w-full text-[#fff]/75 text-lg font-bold font-heading py-4 px-2 sm:p-4 text-center sm:text-left rounded-lg bg-alpha-700'>What it takes to outsource QA / Sustain in-house team</div>
                            <div className='flex flex-col gap-4 p-6 rounded-lg bg-alpha-700  w-full'>
                                <div className='flex flex-col gap-2'>
                                    {/* <div className='text-center text-lg font-semibold text-border-selected flex items-center justify-center gap-2'>
                                        <span>🧪</span> Test Coverage & Creation
                                    </div> */}
                                    <div className='flex flex-col gap-1'>
                                        <p className='flex gap-2 items-center text-[#fff]/75 text-lg'>
                                            <span>Tests for {`${(x.coverage * 100).toFixed(0)}%`} Coverage</span>
                                            <div className="relative">

                                                <Tooltip
                                                    className="w-[45vw] sm:w-80 text-neutral-200 font-heading text-sm font-medium leading-[1.4285]"
                                                >
                                                    Test coverage refers to how thoroughly a product is examined by tests. Achieving comprehensive test coverage means that a significant portion of the application's functionality and code paths have been tested to ensure reliability and quality.
                                                </Tooltip>
                                                <MdInfoOutline />
                                            </div>
                                        </p>
                                        <span className='text-border-selected font-robotomono font-normal text-xl'>{result.testForGivenCoverage}</span>
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                        <p className='text-[#fff]/75 text-lg'>Time to Create {result.testForGivenCoverage} Tests</p>
                                        <p className='flex gap-2 text-border-selected font-robotomono text-xl'>{result.timeToCreateAllTestsDays} <span className='text-primary-light-blue font-inter'>Business days</span></p>
                                    </div>
                                </div>
                            </div>

                            {/* Runs & Failures Section */}
                            <div className='flex flex-col gap-4 p-6 rounded-lg bg-alpha-700  w-full'>
                                <div className='flex flex-col gap-2'>
                                    {/* <div className='text-center text-lg font-semibold text-secondary-blue flex items-center justify-center gap-2'>
                                        <span>📊</span> Runs & Failures
                                    </div> */}
                                    <div className='flex flex-col gap-1'>
                                        <div className='flex gap-2 items-center text-[#fff]/75 text-lg'>
                                            <span className='w-2 h-2 bg-border-selected rounded-sm '></span>
                                            <span>Test Runs</span>
                                            <div className="relative">

                                                <Tooltip
                                                    className="w-56 text-neutral-200 font-heading text-sm font-medium leading-[1.4285]"
                                                >
                                                    Number of tests * Run cadence
                                                </Tooltip>
                                                <MdInfoOutline />
                                            </div>
                                        </div>
                                        <p className='flex gap-2 text-border-selected font-robotomono text-xl'>
                                            {result.testRunsPerMonth}
                                            <span className='text-primary-light-blue font-inter'>/ mo</span>
                                        </p>
                                    </div>
                                    <div className='p-2 mt-2 gap-1 flex flex-wrap items center w-full h-fit rounded border border-border-primary'>
                                        {[...Array(Math.round(result.testFailuresPerMonth / 20))].map((_, index) => (
                                            <span key={index} className='w-2 h-2 bg-[#f4bdec] rounded-sm '></span>
                                        ))}
                                        {[...Array(Math.round(result.testRunsPerMonth / 40))].map((_, index) => (
                                            <span key={index} className='w-2 h-2 bg-border-selected rounded-sm '></span>
                                        ))}
                                    </div>
                                    <div className='flex flex-row gap-2 my-2'>
                                        <p className='flex flex-row items-center gap-2'>
                                            <span className='w-2 h-2 bg-[#f4bdec] rounded-sm '></span>
                                            <span className='text-[#fff]/75 text-sm'>100 test failures</span>
                                        </p>
                                        <p className='flex flex-row items-center gap-2'>
                                            <span className='w-2 h-2 bg-border-selected rounded-sm '></span>
                                            <span className='text-[#fff]/75 text-sm'>100 test runs</span>
                                        </p>
                                    </div>
                                    <div className='flex flex-row justify-between items-center'>


                                        <div className='flex flex-col gap-1'>
                                            <div className='flex gap-2 items-center text-[#fff]/75 text-lg'>
                                                <span className='w-2 h-2 bg-[#f4bdec] rounded-sm '></span>
                                                <span>Test Failures</span>
                                                <div className="relative">

                                                    <Tooltip
                                                        className="w-fit text-neutral-200 font-heading text-sm font-medium leading-[1.4285]"
                                                    >
                                                        <span className="whitespace-nowrap">

                                                            Tests runs * Fail rate
                                                        </span>
                                                    </Tooltip>
                                                    <MdInfoOutline />
                                                </div>
                                            </div>
                                            <div className='flex flex-col gap-1'>
                                                <p className=' flex gap-2 font-robotomono text-xl text-[#b94ea8]'>
                                                    {result.testFailuresPerMonth}
                                                    <span className='text-primary-light-blue font-inter'>/ mo</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <div className='flex gap-2 items-center  text-[#fff]/75 text-lg'>
                                                <span>Test Maintenance</span>
                                                <div className="relative">
                                                    <Tooltip
                                                        left
                                                        className="w-56 text-neutral-200 font-heading text-sm font-medium leading-[1.4285]"
                                                    >
                                                        <span>

                                                            Maintenance time includes everything from re-running the test, reproducing the failure, debugging, PR reviews, and re-deploying the test code.
                                                        </span>
                                                    </Tooltip>
                                                    <MdInfoOutline />
                                                </div>
                                            </div>
                                            <p className='flex gap-2 text-border-selected font-robotomono text-xl'>
                                                {result.testMaintainenceHoursPerMonth}
                                                <span className='text-primary-light-blue font-inter'>hrs / mo</span>
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Team Salary Section */}
                            <div className='flex flex-col gap-4 p-6 rounded-lg bg-alpha-700  w-full'>
                                <div className='flex flex-col gap-2'>
                                    {/* <div className='text-center text-lg font-semibold text-green-400 flex items-center justify-center gap-2'>
                                        <span>💼</span> Team Salary
                                    </div> */}
                                    <div className='flex flex-col gap-1'>
                                        <div className='flex gap-2 items-center text-[#fff]/75 text-lg'>
                                            <span>Number of QA Engineers</span>
                                            {/* <div
                                                className="tooltip tooltip-left"
                                                data-tip={`Maintenance time includes everything from re-running the test, reproducing the failure, debugging, PR reviews, and re-deploying the test code.`}>
                                                <MdInfoOutline />
                                            </div> */}
                                        </div>
                                        <p className='flex gap-2 text-border-selected font-robotomono text-xl'>{result.numberOfQAEsInTeam}</p>
                                    </div>
                                    <div className='p-2 mt-2 gap-1 flex flex-wrap items center w-full h-fit rounded border border-border-primary'>
                                        {[...Array((result.numberOfQAEsInTeam))].map((_, index) => (
                                            <span key={index}><MdPerson className='text-border-selected' /></span>
                                        ))}
                                    </div>

                                    <div className='flex flex-row justify-between'>
                                        <div className='flex flex-col gap-1'>
                                            <p className='flex gap-2 items-center text-[#fff]/75 text-lg'>Number of SDETs</p>
                                            <span className='flex gap-2 text-border-selected font-robotomono text-xl'>{result.numberOFSDETsInTeam}</span>
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <p className='flex gap-2 items-center text-[#fff]/75 text-lg'>Total Team Salary</p>
                                            <p className='flex gap-2 text-border-selected font-robotomono text-xl'>
                                                <span>${result.totalTeamSalary}</span>
                                                <span className='text-primary-light-blue font-inter'>
                                                    / year</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Running Costs Section */}
                            <div className='flex flex-col gap-4 p-6 rounded-lg bg-alpha-700  w-full'>
                                <div className='flex flex-col gap-2'>
                                    {/* <div className='text-center text-lg font-semibold text-purple-400 flex items-center justify-center gap-2'>
                                        <span>💰</span> Running Costs ({x.runType === 'sequential' ? "Sequential" : "Parallel"})
                                    </div> */}
                                    <div className='flex flex-col gap-1'>
                                        <p className='flex gap-2 items-center text-[#fff]/75 text-lg'>Running Hours</p>
                                        <p className='flex gap-2 text-border-selected font-robotomono text-xl'>
                                            {x.runType === 'sequential' ? result.runningHoursPerYearForSequentialRuns : result.runningHoursPerYearForParallelRuns}
                                            <span className='text-primary-light-blue font-inter'>
                                                / year</span>
                                        </p>
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                        <p className='flex gap-2 items-center text-[#fff]/75 text-lg'>{x.runType === 'sequential' ? 'Sequential' : 'Parallel'} Run Cost </p>
                                        <p className='flex gap-2 text-border-selected font-robotomono text-xl'>
                                            <span> ${x.runType === 'sequential' ? result.sequentialRunCostPerYear : result.parallelRunCostPerYear}</span>
                                            <span className='text-primary-light-blue font-inter'>
                                                / year</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="flex flex-col gap-2 sm:sticky h-fit pt-10" style={{ top: (navBarHeight - 32) + "px" }}>
                    { getCostCard() }
                    <SecondaryButton
                        textClass='text-secondary-yellow-primary'
                        onClick={()=>window.open("https://dl.getautoflowstudio.com/book-a-demo","_blank", "noreferrer")}
                    >
                        Book a FREE demo
                    </SecondaryButton>
                </div>
            </div>


        </div>
    );
};

export default PricingCalculator;