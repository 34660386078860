import React, { useState } from 'react'
import { getColorFromTailwindClass } from "../utils/utlilityFunctions/getColorFromTailwindClass";
/**
 * A Primary Button.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} [props.iconLeft] - The icon to display on the left side.
 * @param {React.ReactNode} [props.iconRight] - The icon to display on the right side.
 * @param {string} [props.textClass] - The class for main text.
 * @param {string} [props.customClass] - The class for button's background color.
 */

export const SecondaryButton = (props) => {
    const actualTextColor = getColorFromTailwindClass(props.textClass,'color');
    const [ bgColor, setBgColor ] = useState('transparent');
    const [ textColor, setTextColor ] = useState(actualTextColor);

    function adjustLuminescence(adjustment) {
        // Extract the HSL values using regex
        const hslPattern = /hsla?\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\s*(,\s*[\d.]+)?\)/;
        const match = actualTextColor.match(hslPattern);
    
        if (!match) {
            throw new Error("Invalid HSL/HSLA color format");
        }
    
        const hue = parseInt(match[1]);
        const saturation = parseFloat(match[2]);
        let lightness = parseFloat(match[3]);
        const alpha = match[4] ? match[4].trim() : "";
    
        // Adjust the lightness (luminescence), keeping it within [0, 100]
        lightness = Math.min(100, Math.max(0, lightness + adjustment));
    
        // Return the new HSL or HSLA color string
        return `hsl${alpha ? "a" : ""}(${hue}, ${saturation}%, ${lightness}%${alpha})`;
    }
    

    const handleMouseOver = () =>{
        setBgColor(adjustLuminescence(-33));
    }

    const handleMouseOut = () =>{
        setBgColor('transparent');
    }

    const handleMouseDown = () =>{
        setBgColor(actualTextColor);
        setTextColor(adjustLuminescence(-22));
    }

    const handleMouseUp = () =>{
        setBgColor('transparent');
        setTextColor(actualTextColor);
    }

    const handleFocus = () =>{ 
        setBgColor(adjustLuminescence(-9));
    }


  return (
    <button id={props?.id} title={props?.title} className={`${props?.customClass} whitespace-nowrap flex items-center ${props.iconLeft || props.iconLeft?'justify-between':'justify-center'} gap-2 px-5 ${  props.skinny ? " py-1 text-body-md " : props.slim ? " py-2 text-body-md " : " py-3 " } font-500 rounded-lg`}
        style={{
            color: textColor,
            background:bgColor,
            border:`1px solid ${textColor}`
        }}

        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onFocus={handleFocus}
        onClick={props?.onClick}
    >
        {
            props.iconLeft && props.iconLeft
        }
        {
            props.children
        }
        {
            props.iconRight && props.iconRight
        }
    </button>
  )
}